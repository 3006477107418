
.tags {
  min-height: 80px;
}
.borrowing-btns {
  div:first-of-type {
    margin-left: auto;
  }
}

.borrowing-details {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $grey-blue-900;
  border-radius: 8px;
  .member {
    width: 33.333%;
    .b-header {
      background-color: $grey-blue-900;
      padding: 15px 25px;
      color: white;
      border-top-left-radius: 6px;
      h3 {
        padding: 0px;
        margin: 0px;
        font-family: $font-poppins-medium;
        letter-spacing: 0.5px;
        font-size: 18px;
      }
    }
  }
  .material {
    width: 33.333%;
    .b-header {
      background-color: $grey-blue-900;
      padding: 15px 25px;
      color: white;
      h3 {
        padding: 0px;
        margin: 0px;
        font-family: $font-poppins-medium;
        letter-spacing: 0.5px;
        font-size: 18px;
      }
    }
  }
  .borrowing {
    width: 33.333%;
    .b-header {
      background-color: $grey-blue-900;
      padding: 15px 25px;
      color: white;
      border-top-right-radius: 6px;
      h3 {
        padding: 0px;
        margin: 0px;
        font-family: $font-poppins-medium;
        letter-spacing: 0.5px;
        font-size: 18px;
      }
    }
  }
  .b-details {
    padding: 15px 25px;
    color: #6c767e;
  }
  .attr-col {
    font-family: $font-poppins-regular;
    letter-spacing: 0.5px;
  }
  .attr-val {
    font-family: $font-poppins-regular;
    letter-spacing: 0.5px;
  }
}

//media query

@media screen and (min-width: 992px) and (max-width: 1500px) {
  .borrowing-details {
    display: flex;
    flex-wrap: wrap;
    border: none;
    border-radius: 8px;
    .member {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .material {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .borrowing {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .borrowing-details {
    display: flex;
    flex-wrap: wrap;
    border: none;
    border-radius: 8px;
    .member {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .material {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .borrowing {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .borrowing-details {
    display: flex;
    flex-wrap: wrap;
    border: none;
    border-radius: 8px;
    .member {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 25px;
      }
      .b-details {
        padding: 15px 25px;
      }
    }
    .material {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 25px;
      }
      .b-details {
        padding: 15px 25px;
      }
    }
    .borrowing {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 25px;
      }
      .b-details {
        padding: 15px 25px;
      }
    }
  }
}
@media (max-width: 576px) {
  .borrowing-details {
    display: flex;
    flex-wrap: wrap;
    border: none;
    border-radius: 8px;
    .member {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px;
      }
      .b-details {
        padding: 15px;
      }
    }
    .material {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px;
      }
      .b-details {
        padding: 15px;
      }
    }
    .borrowing {
      margin-top: 30px;
      width: 100%;
      border: 1px solid $grey-blue-900;
      border-radius: 8px;
      .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px;
      }
      .b-details {
        padding: 15px;
      }
    }
  }
}
