.error-404 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  background-color: $grey-blue-400;

  .logo {
    margin-bottom: 5rem;
  }

  > div {
    h1 {
      color: $grey-blue-900;
      font-size: 12.5rem;
      letter-spacing: 0.1em;
      margin: 0.025em 0;
      text-shadow: 0.05em 0.05em 0 rgb(0 44 62 / 20%);
      white-space: nowrap;
      font-family: $font-poppins-bold;

      > span {
        -webkit-animation: spooky 2s alternate infinite linear;
        animation: spooky 2s alternate infinite linear;
        color: $accent-blue-100;
        display: inline-block;
      }

      @media (max-width: 767px) {
        font-size: 7.5rem;
      }

      @media (max-width: 576px) {
        font-size: 5.5rem;
      }
    }

    h2 {
      color: $grey-blue-900;
      font-family: $font-poppins-semibold;
      margin-bottom: 0.4em;
    }

    p {
      color: $grey-blue-750;
      margin-top: 0;
      font-family: $font-poppins-medium;
      font-size: 16px;
    }
  }
}

@-webkit-keyframes spooky {
  from {
    transform: translatey(0.15em) scaley(0.95);
  }
  to {
    transform: translatey(-0.15em);
  }
}

@keyframes spooky {
  from {
    transform: translatey(0.15em) scaley(0.95);
  }
  to {
    transform: translatey(-0.15em);
  }
}
