.button-gray {
  padding: 12px 25px;
  background: $btn-gray-bg;
  color: $btn-gray-color;
  border: 1px solid $btn-gray-bg;
  border-radius: 5px;
  font-family: $font-poppins-bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  outline: none;

  &:hover {
    background: $btn-gray-bg-hover;
    box-shadow: $btn-gray-bg-hover-shadow;
    border: 1px solid $btn-gray-bg-hover;
    transition: 0.3s;
  }
  &:active {
    background: $btn-gray-bg-focus;
    border: 1px solid $btn-gray-bg-focus;
    box-shadow: none;
    transition: 0.3s;
  }
}

.button-white-bordered {
  padding: 9.5px;
  background: transparent;
  color: $white;
  border: 1px solid $white;
  border-radius: 50px;
  font-family: $font-poppins-medium;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  outline: none;

  &:hover {
    background: $btn-gray-bg-hover;
    box-shadow: $btn-gray-bg-hover-shadow;
    color: $accent-blue-200;
    transition: 0.3s;

    .icon {
      background: $accent-blue-200;
    }
  }
  &:active {
    background: $btn-gray-bg-focus;
    box-shadow: none;
    transition: 0.3s;

    .icon {
      background: $accent-blue-200;
    }
  }

  .icon {
    background: $white;
    width: 30px;
    height: 30px;
    mask-size: 30px;
  }

  &.has-icon {
    display: inline-block;

    > i {
      margin: 0 0 -4px 0;
    }
  }
}

.button-white-rounded {
  display: flex;
  padding: 12px;
  background: transparent;
  border-radius: 50%;
  border: none;
  outline: none;

  &:hover {
    background: $btn-gray-bg-hover;
    box-shadow: $btn-gray-bg-hover-shadow;
    transition: 0.3s;
  }
  &:active {
    background: $btn-gray-bg-focus;
    box-shadow: none;
    transition: 0.3s;
  }
}

.button-blue {
  padding: 9.5px 25px;
  background: $accent-blue-100;
  color: $white;
  border: 1px solid $accent-blue-100;
  border-radius: 5px;
  font-family: $font-poppins-medium;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  outline: none;

  &:hover {
    background: $accent-blue-200;
    box-shadow: $btn-blue-bg-hover-shadow;
    border: 1px solid $accent-blue-200;
    transition: 0.3s;
  }
  &:active {
    background: $grey-blue-950;
    border: 1px solid $grey-blue-950;
    box-shadow: none;
    transition: 0.3s;
  }

  .icon {
    background: $white;
  }

  &.has-icon {
    display: inline-block;

    > i {
      margin: 0px 7px -4px 0;
    }
  }
}

.button-blue-border {
  padding: 9.5px 25px;
  background: $white;
  color: $accent-blue-100;
  border: 1px solid $accent-blue-100;
  border-radius: 5px;
  font-family: $font-poppins-medium;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  outline: none;

  .spinner-icon {
    border-top-color: $accent-blue-100 !important;
    border: 3px solid rgb(31 84 197 / 22%);
  }

  &:hover {
    background: $accent-blue-200;
    color: $white;
    box-shadow: $btn-blue-bg-hover-shadow;
    border: 1px solid $accent-blue-200;
    transition: 0.3s;

    .spinner-icon {
      border-top-color: $white !important;
      border: 3px solid rgba(255, 255, 255, 0.3);
    }
  }
  &:active {
    background: $grey-blue-950;
    color: $white;
    border: 1px solid $grey-blue-950;
    box-shadow: none;
    transition: 0.3s;

    .spinner-icon {
      border-top-color: $white !important;
      border: 3px solid rgba(255, 255, 255, 0.3);
    }
  }

  .icon {
    background: $white;
  }

  &.has-icon {
    display: inline-block;

    > i {
      margin: 0px 7px -4px 0;
    }
  }
}

.button-blue-item {
  padding: 9.5px 17px;
  background: $accent-blue-100;
  color: $white;
  border: 1px solid $accent-blue-100;
  border-radius: 5px;
  font-family: $font-poppins-medium;
  font-size: 20px;
  line-height: 19px;
  text-transform: uppercase;
  outline: none;

  &:hover {
    background: $accent-blue-200;
    box-shadow: $btn-blue-bg-hover-shadow;
    border: 1px solid $accent-blue-200;
    transition: 0.3s;
  }
  &:active {
    background: $grey-blue-950;
    border: 1px solid $grey-blue-950;
    box-shadow: none;
    transition: 0.3s;
  }

  .icon {
    background: $white;
  }

  &.has-icon {
    display: inline-block;

    > i {
      margin: 0px 7px -4px 0;
    }
  }
}

.button-blue-border-item {
  padding: 9.5px 17px;
  background: transparent;
  color: $accent-blue-100;
  border: 1px solid $accent-blue-100;
  border-radius: 5px;
  font-family: $font-poppins-medium;
  font-size: 20px;
  line-height: 19px;
  text-transform: uppercase;
  outline: none;

  &:hover {
    background: $accent-blue-200;
    color: $white;
    box-shadow: $btn-blue-bg-hover-shadow;
    border: 1px solid $accent-blue-200;
    transition: 0.3s;
  }
  &:active {
    background: $grey-blue-950;
    color: $white;
    border: 1px solid $grey-blue-950;
    box-shadow: none;
    transition: 0.3s;
  }

  .icon {
    background: $white;
  }

  &.has-icon {
    display: inline-block;

    > i {
      margin: 0px 7px -4px 0;
    }
  }
}

.button-blue-borrow {
  padding: 9.5px 20px;
  background: $white;
  color: $accent-blue-100;
  border: 1px solid $accent-blue-100;
  border-radius: 50px;
  font-family: $font-poppins-medium;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  outline: none;

  &:hover {
    background: $accent-blue-200;
    color: $white;
    box-shadow: $btn-blue-bg-hover-shadow;
    border: 1px solid $accent-blue-200;
    transition: 0.3s;
  }
  &:active {
    background: $grey-blue-950;
    color: $white;
    border: 1px solid $grey-blue-950;
    box-shadow: none;
    transition: 0.3s;
  }

  .icon {
    background: $white;
  }

  &.has-icon {
    display: inline-block;

    > i {
      margin: 0px 7px -4px 0;
    }
  }
}

.button-gray-blue {
  display: flex;
  align-items: center;
  padding: 9.5px 25px;
  background: $btn-gray-bg;
  color: $grey-blue-800;
  border: 1px solid $btn-gray-bg;
  border-radius: 5px;
  font-family: $font-poppins-medium;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  outline: none;

  &:hover {
    background: $btn-gray-bg-hover;
    box-shadow: $btn-gray-bg-hover-shadow;
    border: 1px solid $btn-gray-bg-hover;
    transition: 0.3s;
  }
  &:active {
    background: $btn-gray-bg-focus;
    border: 1px solid $btn-gray-bg-focus;
    box-shadow: none;
    transition: 0.3s;
  }

  .icon {
    background: $grey-blue-800;
  }

  &.has-icon {
    display: inline-block;

    > i {
      margin: 0px 7px -4px 0;
    }
  }
}

.button-unstyled,
.button-unstyled:focus,
.button-unstyled:active {
  background: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
}

.button-unstyled-icon,
.button-unstyled-icon:focus,
.button-unstyled-icon:active {
  background: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
  display: inline-flex;

  .show-icon {
    &:hover {
      background: $purple-color;
    }
  }

  .download-icon-gray {
    &:hover {
      background: $accent-blue-100;
    }
  }

  .edit-icon {
    &:hover {
      background: $accent-blue-100;
    }
  }

  .delete-icon {
    &:hover {
      background: $red-color;
    }
  }

  .restore-icon {
    &:hover {
      background-color: $btn-blue-bg-hover;
    }
  }

  .print-icon {
    &:hover {
      background: $accent-blue-100;
    }
  }

  .download-icon {
    &:hover {
      background: $accent-blue-100;
    }
  }

  &.bordered {
    background: #ebf1ff;
    border: 1px solid #8da6e1;
    border-radius: 4px;

    &:hover {
      border: 1px solid #1f54c5 !important;
    }

    .edit-icon {
      &:not(:hover) {
        background: #8da6e1;
      }
    }
  }
}
