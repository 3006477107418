//header scss
.navbar {
  background: #fff;
  border-bottom: 2px solid #007bff;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 10%);
  min-height: 118px;
  .logout-button {
    padding-left: 10px;
    align-items: center;
    .logout-btn {
      height: 45px;
      border: 2px solid #007aff;
      width: 120px;
    }
  }
  .btn-unstyled {
    &.sidebarCollapse-menu {
      margin-right: 35px;
      display: none;
    }
  }
  .big-navi-icon {
    margin-right: 35px;
    padding: 0;
    background: none;
    outline: none;
  }
}

/*header*/

#content {
  min-height: calc(100vh - 785px);
}

.header {
  min-height: 100vh;
  position: relative;
  &:after {
    max-height: 100vh;
    min-height: 100vh;
    background-position: top center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    bottom: 0;
  }
  .search {
    margin-top: 0 !important;
    .quote {
      margin-bottom: 60px;
    }
    #advanced-search {
      color: white;
      font-family: "Galano Grotesque Medium", sans-serif;
      letter-spacing: 0.5px;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      padding-left: 5px;
    }
  }
  &.frontpage {
    background: unset;
    .header {
      .search-description {
        margin-top: 25vh;
      }
    }
  }
  .search-description {
    margin-top: 15vh;
    position: relative;
    .category_title {
      text-align: center;
      margin-bottom: 40px;
      h1 {
        font-size: 60px;
        font-family: "Galano Grotesque SemiBold";
        color: #ffffff;
      }
      p {
        font-size: 30px;
        font-family: "Galano Grotesque Medium";
        color: #fff;
      }
    }
  }
  .main-menu {
    width: 100%;
    padding: 35px 30px 15px;
    .nav-wrapper {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      justify-content: flex-end;
      position: relative;
      margin: 0;
      .navsection-mobile {
        display: none;
      }
      .navsection-primary {
        display: flex;
        align-items: center;
        height: 50px;
        ul {
          display: flex;
          padding: 0 8px;
          list-style-type: none;
          li {
            display: flex;
            align-items: center;
            a {
              font-size: 16px;
              margin-right: 40px;
              font-family: "Galano Grotesque Medium", sans-serif;
              color: #fff;
              text-decoration: none;
              text-transform: uppercase;
              &:hover {
                color: #007aff;
              }
              &.login-button {
                padding: 10px 40px;
                border: 2px solid #fff;
                border-radius: 6px;
                margin-right: 0;
                &:hover {
                  background: #fff;
                  color: #007aff;
                }
              }
            }
            .logged-in {
              position: relative;
              button {
                padding: 0;
                background: none;
                border: none;
                &:focus {
                  background: none;
                  padding: 0;
                  outline: none;
                }
                &:hover {
                  background: none;
                  padding: 0;
                  outline: none;
                }
                &:active {
                  background: none;
                  padding: 0;
                  outline: none;
                }
              }
              .user-actions {
                background: #002c3e;
                right: 0;
                top: 55px;
                border-radius: 6px;
                display: none;
                position: absolute;
                z-index: 999;
                padding: 0;
                overflow: hidden;
                width: 180px;
                &:hover {
                  display: block;
                }
                &.active {
                  display: block;
                }
                li {
                  a {
                    padding: 10px 15px;
                    width: 100%;
                    margin: 0;
                    text-transform: none;
                    display: flex;
                    align-items: center;
                    &:hover {
                      background: #f0f7ff;
                      color: #002c3e;
                    }
                    img {
                      width: 24px;
                      height: 24px;
                    }
                    span {
                      padding-left: 8px;
                    }
                  }
                  form {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;
                    .logout-button {
                      background: none;
                      outline: none;
                      border: none;
                      color: #fff;
                      width: 100%;
                      text-align: left;
                    }
                    &:hover {
                      background: #f0f7ff;
                      .logout-button {
                        color: #002c3e;
                      }
                    }
                  }
                  &.profile-u {
                    a {
                      &:hover {
                        img {
                          opacity: 0;
                        }
                        span {
                          &:before {
                            content: "";
                            position: absolute;
                            left: 15px;
                            height: 25px;
                            width: 25px;
                            background-size: contain;
                          }
                          &.user-profile {
                            &:before {
                              content: "";
                              position: absolute;
                              left: 15px;
                              height: 25px;
                              width: 25px;
                              background-size: contain;
                            }
                          }
                        }
                      }
                    }
                  }
                  &.change-pw {
                    a {
                      &:hover {
                        img {
                          opacity: 0;
                        }
                        span {
                          &:before {
                            content: "";
                            content: "";
                            position: absolute;
                            left: 14px;
                            height: 30px;
                            width: 23px;
                            background: url(../../assets/images/icons/password-icon.svg)
                              no-repeat;
                            top: 50%;
                            transform: translateY(-50%);
                          }
                        }
                      }
                    }
                  }
                  &.logout-u {
                    form {
                      &:hover {
                        cursor: pointer;
                        img {
                          opacity: 0;
                        }
                        &:before {
                          content: "";
                          position: absolute;
                          left: 15px;
                          height: 25px;
                          width: 25px;
                          background-size: contain;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .frontpage {
    .header {
      .search-description {
        margin-top: 10vh !important;
      }
    }
  }
}
@media (max-width: 767px) {
  .header {
    .search-description {
      margin-top: 10vh !important;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    .search-description {
      .category_title {
        h1 {
          font-size: 48px;
        }
        p {
          font-size: 28px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .header {
    .search-description {
      .category_title {
        h1 {
          font-size: 36px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .header {
    .main-menu {
      .nav-wrapper {
        .navsection-primary {
          ul {
            li {
              a {
                margin-right: 25px;
                &.login-button {
                  padding: 10px 25px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .header {
    .main-menu {
      .nav-wrapper {
        .navsection-primary {
          ul {
            li {
              a {
                font-size: 14px;
                &.login-button {
                  padding: 8px 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .header {
    .main-menu {
      .nav-wrapper {
        .navsection-primary {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .header {
    .main-menu {
      .nav-wrapper {
        .navsection-mobile {
          display: flex;
        }
        .navsection-primary {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .header {
    .main-menu {
      padding: 30px 0 15px;
      .container {
        padding: 0;
      }
    }
  }
}

// Sidebar
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-direction: column;

  #page-topbar {
    background-color: $white;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1002;

    .navbar-header {
      align-items: center;
      display: flex;
      height: 80px;
      justify-content: space-between;
      margin: 0 auto;

      .navbar-brand-box {
        height: 80px;
        width: 250px;
        display: flex;
        gap: 0 10px;
        padding: 0 16px;
        align-content: center;
        align-items: center;
        background: $grey-blue-900;

        .initals {
          min-width: 50px;
          min-height: 50px;
          background: $grey-blue-500;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;

          span {
            font-size: 20px;
            color: $grey-blue-800;
            font-family: $font-poppins-medium;
          }
        }

        .user-data {
          display: flex;
          flex-direction: column;

          .user-role {
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            color: $accent-blue-500;
            font-family: $font-poppins-regular;
          }

          .user-full-name {
            font-size: 16px;
            line-height: 18px;
            text-transform: uppercase;
            color: $white;
            font-family: $font-poppins-regular;
            word-break: break-word;
          }
        }

        @media (max-width: 992px) {
          display: none;
        }
      }

      .main-navbar {
        width: calc(100% - 250px);
        height: 80px;
        margin: 0px 70px;
        border-bottom: 2px solid $accent-blue-100;
        justify-content: space-between;
        align-items: center;

        .toggle-menu {
          display: none;
        }

        .logo-icon {
          display: none;
        }

        @media (min-width: 993px) {
          .hamburger-button {
            display: none;
          }
        }

        @media (max-width: 992px) {
          width: 100%;
          margin: 0px 25px;

          .toggle-menu {
            display: block;
          }

          .password-btn,
          .logout-btn {
            display: none;
          }
        }

        @media (max-width: 480px) {
          .logo-icon {
            display: block;
          }

          .logo {
            display: none;
          }
        }
        .header-user-dropdown {
          display: none;

          button {
            background: none;
            border: none;
            display: flex;
            align-items: center;

            .initals {
              min-width: 50px;
              min-height: 50px;
              background: $grey-blue-500;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                font-size: 20px;
                color: $grey-blue-800;
                font-family: $font-poppins-medium;
              }
            }
          }

          .dropdown-menu {
            &.show {
              padding: 10px;

              li {
                color: $grey-blue-800;
                padding: 5px 0;
                font-family: $font-poppins-regular;
                font-size: 14px;

                a {
                  color: $grey-blue-800;
                  padding: 5px 0;
                }

                .dropdown-divider {
                  margin: 0;
                }

                button {
                  padding: 0;
                  width: 100%;
                  color: $grey-blue-800;
                  font-family: $font-poppins-medium;
                }
              }
            }
          }

          @media (max-width: 992px) {
            display: flex;
          }
        }
      }
    }
  }

  .vertical-menu {
    width: 250px;
    position: fixed;
    top: 0;
    bottom: 0;
    padding-top: 90px;
    background: $grey-blue-900;
    color: $white;
    transition: all 0.3s;
    height: 100%;
    z-index: 1001;

    .navbar-brand-box {
      height: 80px;
      display: flex;
      gap: 0 10px;
      padding: 0 16px;
      align-content: center;
      align-items: center;

      .initals {
        min-width: 50px;
        min-height: 50px;
        background: $sidebar-initials-bg;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 20px;
          color: $sidebar-initials-color;
          font-family: $font-poppins-regular;
        }
      }

      .user-data {
        display: flex;
        flex-direction: column;

        .user-role {
          font-size: 14px;
          line-height: 14px;
          text-transform: uppercase;
          color: $accent-blue-500;
          font-family: $font-poppins-regular;
        }

        .user-full-name {
          font-size: 16px;
          line-height: 18px;
          text-transform: uppercase;
          color: $white;
          font-family: $font-poppins-regular;
        }
      }
    }

    #sidebar-menu {
      padding: 0;
      padding-bottom: 90px;

      .menu-item {
        padding: 0 12px;
        a {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          padding: 10px;
          color: $grey-blue-700;
          background: none;
          border: none;
          border-radius: 8px;
          gap: 6px;

          span {
            font-family: $font-poppins-medium;
            font-size: 16px;
            text-transform: uppercase;
            color: $grey-blue-700;
          }

          .sidebar-arrow {
            transform: rotate(180deg);
            transition: 0.3s;
            margin-left: auto;
            &.icon {
              background: $accent-blue-500;
            }
          }

          .icon {
            background: $white;
          }

          &.collapsed {
            .sidebar-arrow {
              transform: rotate(0deg);
            }

            span {
              color: $grey-blue-700;
            }

            .icon {
              background: $grey-blue-700;
            }
          }

          &:hover {
            transition: 0.3s;
            background-color: $grey-blue-750;

            span {
              color: $white;
            }

            .icon {
              background: $white;
            }
          }

          &.active {
            color: $white;

            span {
              font-family: $font-poppins-medium;
              font-size: 16px;
              text-transform: uppercase;
              color: $white;
            }
          }
        }

        button {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          padding: 10px;
          color: $white;
          background: none;
          border: none;
          border-radius: 8px;
          gap: 6px;

          span {
            font-family: $font-poppins-medium;
            font-size: 15px;
            text-transform: none;
            color: $white;
            text-align: left;
            line-height: 1;
          }

          .sidebar-arrow {
            transform: rotate(180deg);
            transition: 0.3s;
            margin-left: auto;
            &.icon {
              background: $accent-blue-500;
            }
          }

          .icon {
            background: $white;
          }

          &.collapsed {
            .sidebar-arrow {
              transform: rotate(0deg);
            }

            span {
              color: $grey-blue-700;
            }

            .icon {
              background: $grey-blue-700;
            }
          }

          &:hover {
            transition: 0.3s;
            background-color: $grey-blue-750;

            span {
              color: $white;
            }

            .icon {
              background: $white;
            }
          }
        }

        div {
          .sub-menu {
            background: $submenu-background;
            border-radius: 8px;
            overflow: hidden;

            li {
              display: flex;

              a {
                width: 100%;
                padding: 10px 20px 10px 40px;
                color: $grey-blue-700;
                margin-bottom: 0;

                &:hover,
                &.active {
                  background: $accent-blue-200;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      display: none;
      width: 0;
      visibility: hidden;

      &.open {
        display: block;
        width: 250px;
        visibility: visible;
      }
    }
  }

  .main-content {
    width: calc(100% - 250px);
    margin-left: 250px;
    min-height: calc(100vh - 51px);

    .page-content {
      padding: 130px 70px 50px;
    }

    @media (max-width: 992px) {
      width: 100%;
      margin-left: 0;

      .page-content {
        padding: 105px 25px 25px;
      }
    }

    @media (min-width: 993px) and (max-width: 1148px) {
      min-height: calc(100vh - 72px);
    }
  }

  .footer {
    margin-left: 250px;
    padding: 15px 70px;
    color: $grey-blue-700;
    background-color: $grey-blue-800;
    font-family: $font-poppins-regular;
    font-size: 14px;
    > div {
      > div {
        display: flex;
        align-items: center;
      }
    }

    @media (min-width: 768px) {
      .developed-by {
        justify-content: flex-end;
      }
    }

    @media (max-width: 992px) {
      margin-left: 0;
      padding: 15px 25px;
    }
  }
}

// Homepage
// Header
.header {
  min-height: 500px;
  position: relative;

  .main-navbar {
    display: flex;
    gap: 20px;
    padding: 25px 100px;
    justify-content: space-between;

    .navbar-menu {
      ul {
        display: flex;
        gap: 30px;
        padding: 0 8px;
        list-style-type: none;
        margin-bottom: 0;

        li {
          display: flex;
          align-items: center;
          cursor: pointer;
          a {
            font-family: $font-poppins-regular;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            text-decoration: none;
            color: $white;

            &:hover {
              color: $accent-blue-500;
            }

            &.button-white-bordered {
              padding: 9.5px 20px;
              background: transparent;
              color: $white;
              border: 1px solid $white;
              border-radius: 5px;
              font-family: $font-poppins-medium;
              font-size: 14px;
              line-height: 19px;
              text-transform: uppercase;
              outline: none;

              &:hover {
                background: $btn-gray-bg-hover;
                box-shadow: $btn-gray-bg-hover-shadow;
                color: $grey-blue-800;
                border: 1px solid $btn-gray-bg-hover;
                transition: 0.3s;
              }
              &:active {
                background: $btn-gray-bg-focus;
                border: 1px solid $btn-gray-bg-focus;
                box-shadow: none;
                color: $grey-blue-800;
                transition: 0.3s;
              }
            }
          }
        }

        &.dropdown-menu {
          display: none;

          &.show {
            display: block;
            padding: 5px 0;
            transform: translate(0px, 5px);
            box-shadow: 0px 0px 4px rgb(83 124 196 / 30%);
            border-radius: 6px;
            background-color: $white;
            border: none;

            li {
              font-family: $font-poppins-regular;
              font-size: 14px;
              color: $grey-blue-900;
              display: flex;
              width: 100%;
              white-space: nowrap;
              background-color: transparent;
              border: 0;
              cursor: pointer;

              > * {
                width: 100%;
                display: flex;
                align-items: center;
                font-family: $font-poppins-regular;
                font-size: 14px;
                color: $grey-blue-900;
                padding: 4px 16px;
              }

              .icon {
                background: $grey-blue-900;
              }

              &:hover {
                color: var(--bs-dropdown-link-hover-color);
                background-color: var(--bs-dropdown-link-hover-bg);
              }
            }
          }
        }

        @media (max-width: 1200px) {
          gap: 15px;
        }
      }

      @media (max-width: 992px) {
        display: none;
      }
    }

    .navbar-menu-mobile {
      display: none;

      .dropdown-menu {
        display: none;

        &.show {
          display: block;
          padding: 5px 0;
          transform: translate(0px, 5px);
          box-shadow: 0px 0px 4px rgb(83 124 196 / 30%);
          border-radius: 6px;
          background-color: $white;
          border: none;

          li {
            font-family: $font-poppins-regular;
            font-size: 14px;
            color: $grey-blue-900;
            display: flex;
            width: 100%;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
            cursor: pointer;

            > * {
              width: 100%;
              display: flex;
              align-items: center;
              font-family: $font-poppins-regular;
              font-size: 14px;
              color: $grey-blue-900;
              padding: 4px 16px;
            }

            .icon {
              background: $grey-blue-900;
            }

            &:hover {
              color: var(--bs-dropdown-link-hover-color);
              background-color: var(--bs-dropdown-link-hover-bg);
            }
          }

          @media (max-width: 295px) {
            left: auto;
            right: auto;
          }
        }
      }

      .mobilemenu-wrapper {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        cursor: pointer;
        height: 26px;
        width: 30px;
        z-index: 500;
        margin-top: 10px;

        &.active {
          position: fixed;
          right: 25px;
          top: 30px;
          margin-top: 0;

          &.loggedin {
            top: 60px;
          }

          .bars {
            &:nth-child(1) {
              transform: translate(3px, 2px) rotate(45deg);
              width: 20px;
              background: $grey-blue-900;
            }

            &:nth-child(2) {
              transform: translateX(-700%);
              opacity: 0;
            }

            &:nth-child(3) {
              transform: translate(3px, -2px) rotate(-45deg);
              width: 20px;
              background: $grey-blue-900;
            }
          }
        }

        .bars {
          background: $white;
          display: block;
          height: 3px;
          width: 100%;
          transition: all 0.35s ease;

          &:nth-of-type(3) {
            width: 60%;
          }
        }
      }
      .overlay {
        background: #0000008c;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        visibility: hidden;
        transition: visibility 0.35s, width 0.35s;
        overflow: auto;
        z-index: 99;

        &.open {
          visibility: visible;
          height: 100%;

          .menu-div {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: 0 !important;
            right: 0;
            background: $grey-blue-900;
            width: 60%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            ul {
              margin: 0;
              text-align: left;
              padding: 0;
              width: 100%;

              .main-li {
                background: $white;
                opacity: 1;
                padding: 20px 50px;

                .button-white-rounded {
                  width: max-content;
                  padding: 12px 25px;
                  background: $btn-gray-bg;
                  color: $btn-gray-color;
                  border: 1px solid $btn-gray-bg;
                  border-radius: 5px;
                  font-family: $font-poppins-bold;
                  font-size: 16px;
                  line-height: 19px;
                  text-transform: uppercase;
                  outline: none;
                  text-decoration: none;

                  &:hover {
                    background: $btn-gray-bg-hover;
                    box-shadow: $btn-gray-bg-hover-shadow;
                    border: 1px solid $btn-gray-bg-hover;
                    transition: 0.3s;
                  }
                  &:active {
                    background: $btn-gray-bg-focus;
                    border: 1px solid $btn-gray-bg-focus;
                    box-shadow: none;
                    transition: 0.3s;
                  }
                }

                li {
                  margin-bottom: 10px;

                  > * {
                    color: $grey-blue-900;
                    text-decoration: none;
                    width: max-content;
                    display: flex;
                    font-family: $font-poppins-medium;
                    align-items: center;

                    > svg {
                      margin-right: 4px;
                    }
                  }

                  .icon {
                    background: $grey-blue-900;
                    mask-size: 30px;
                  }

                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }

                @media (max-width: 576px) {
                  padding: 20px 30px;
                }
              }

              &.menu-mob {
                padding: 40px 50px;

                li {
                  margin-bottom: 15px;

                  a {
                    color: $white;
                    font-family: $font-poppins-medium;
                    text-decoration: none;
                    font-size: 17px;
                    max-width: max-content;
                    letter-spacing: 1px;
                  }

                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }

                @media (max-width: 576px) {
                  padding: 30px;
                }
              }
            }

            @media (max-width: 576px) {
              width: 100%;
            }
          }

          li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.15s;

            &.main-li {
              animation: none;
            }

            &:nth-of-type(2) {
              animation-delay: 0.2s;
            }

            &:nth-of-type(3) {
              animation-delay: 0.25s;
            }

            &:nth-of-type(4) {
              animation-delay: 0.3s;
            }

            &:nth-of-type(5) {
              animation-delay: 0.35s;
            }
          }
        }

        ul {
          display: inline-block;
          margin: 40px 0 0;
          position: relative;

          li {
            display: block;
            position: relative;
            opacity: 0;
            font-size: 20px;

            a {
              display: block;
              position: relative;
              overflow: hidden;
              font-family: $font-poppins-medium;
            }
          }
        }
      }

      @media (max-width: 992px) {
        display: flex;
      }
    }

    @media (max-width: 1200px) {
      .logo {
        img {
          height: 45px;
          width: auto;
        }
      }
    }

    @media (max-width: 1200px) {
      padding: 25px 50px;
    }

    @media (max-width: 767px) {
      padding: 25px;
    }
  }

  .banner-container {
    position: relative;
    margin-top: 15vh;
    text-align: center;
    margin-bottom: 40px;
    padding: 0 100px;

    &.databank-banner {
      margin-top: 10vh;
      @media (max-width: 956px) {
        width: 100% !important;
        max-width: 100% !important;
      }
      .title {
        font-size: 65px;
        @media (max-width: 1200px) {
          font-size: 60px;
          line-height: 70px;
        }
        @media (max-width: 860px) {
          white-space: normal !important;
        }

        @media (max-width: 767px) {
          font-size: 36px;
          line-height: 40px;
        }
      }
      .subtitle {
        font-size: 20px;
        white-space: nowrap !important;
        @media (max-width: 1200px) {
          font-size: 20px;
          line-height: 24px;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 20px;
          white-space: normal !important;
        }
      }
    }

    .title {
      font-family: $font-poppins-bold;
      font-size: 76px;
      text-align: center;
      line-height: 85px;
      text-transform: uppercase;
      color: $white;

      @media (max-width: 1200px) {
        font-size: 60px;
        line-height: 70px;
      }

      @media (max-width: 767px) {
        font-size: 36px;
        line-height: 40px;
      }
    }

    .subtitle {
      font-family: $font-poppins-medium;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      color: $white;

      @media (max-width: 1200px) {
        font-size: 20px;
        line-height: 24px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 20px;
      }
    }

    .search-block {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      text-align: left;
      max-width: 900px;
      margin: auto;

      .form-block {
        margin-bottom: 0;

        &:nth-of-type(1) {
          width: calc(40% - 82.5px);

          @media (max-width: 767px) {
            width: calc(50% - 7.5px);
          }

          @media (max-width: 575px) {
            width: 100%;
          }
        }
        &:nth-of-type(2) {
          width: calc(60% - 82.5px);

          @media (max-width: 767px) {
            width: calc(50% - 7.5px);
          }

          @media (max-width: 575px) {
            width: 100%;
          }
        }
        &:nth-of-type(3) {
          width: 135px;

          @media (max-width: 575px) {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      padding: 0 25px;
    }
  }

  &.full-height {
    min-height: 100vh;
    &.databank {
      @media (max-width: 1200px) {
        min-height: 100vh;
      }
    }

    .banner-container {
      margin-top: 25vh;

      @media (max-width: 767px) {
        margin-top: 20vh;
      }
    }
  }

  &:after {
    max-height: 100vh;
    min-height: 500px;
    background: url(../images/banners/main-banner.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    bottom: 0;
    clip-path: polygon(0 0, 100% 0, 100% 83%, 0 95%);
  }
}

// Main
.main-content-container {
  width: 100%;
  overflow-x: hidden;

  .page-content {
    padding: 70px 100px;
    max-width: 1500px;
    margin: auto;

    @media (max-width: 1200px) {
      padding: 50px;
    }

    @media (max-width: 767px) {
      padding: 30px 25px;
    }
  }
}

// Footer
.footer-container {
  text-align: center;
  padding: 70px 100px;
  background-color: $grey-blue-900;
  color: $white;
  &.databank-footer {
    padding: 12px 100px;

    @media (max-width: 1200px) {
      padding: 20px 50px;
    }

    @media (max-width: 767px) {
      padding: 20px 25px;
    }

    @media (max-width: 586px) {
      padding: 25px 20px;
    }
    .footer-icons {
      display: flex;
      gap: 20px;
      align-self: center;

      a {
        img:hover {
          filter: brightness(0) saturate(100%) invert(91%) sepia(25%)
            saturate(7217%) hue-rotate(188deg) brightness(92%) contrast(91%);
        }
      }
    }
    .developed {
      align-self: center;
      text-align: left;
      p {
        margin-bottom: 0;

        @media (max-width: 484px) {
          word-break: break-word;
        }
      }
    }
  }
  .footer-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px 20px;
    padding-bottom: 20px;

    .logo {
      display: flex;
    }

    .info {
      display: flex;
      flex-wrap: wrap;
      gap: 30px 100px;

      .footer-title {
        font-family: $font-poppins-medium;
        font-size: 24px;
        line-height: 28px;
        text-align: left;
        text-transform: uppercase;
        color: $grey-blue-400;
        margin-bottom: 20px;

        @media (max-width: 715px) {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 10px;
        }
      }

      .contact {
        ul {
          margin-bottom: 0;

          li {
            font-family: $font-poppins-light;
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            color: $grey-blue-700;
            margin-bottom: 10px;

            span {
              font-family: $font-poppins-medium;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      .navigation {
        .navigation-list {
          display: flex;
          flex-wrap: wrap;
          gap: 11px 30px;

          ul {
            margin-bottom: 0;

            li {
              line-height: 18px;
              margin-bottom: 17px;

              a {
                font-family: $font-poppins-light;
                font-style: normal;
                font-size: 14px;
                color: $grey-blue-700;

                &:hover {
                  color: $accent-blue-500;
                }
              }

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .footer-copyright {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;

    .developed {
      font-size: 14px;
      line-height: 18px;
      color: $grey-blue-700;
      font-family: $font-poppins-light;

      a {
        text-decoration: none;
        color: $accent-blue-500;

        &:hover {
          color: $accent-blue-100;
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .social-media {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: calc(30% - 10px);

      .social-media-icons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;

        a {
          height: 23px;

          i {
            &:hover {
              background-color: $accent-blue-500;
            }
          }
        }
      }

      @media (max-width: 767px) {
        justify-content: flex-start;
        width: 100%;
      }
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    text-align: left;
    li {
      text-decoration: none;
      a {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 50px;
  }

  @media (max-width: 767px) {
    padding: 30px 25px;
  }

  @media (max-width: 575px) {
    padding: 25px;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(70px, 0px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}
