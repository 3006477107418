//Page padding
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.small-offset-left {
  padding: 0 100px;
}

/* Main title */

.partial-title {
  color: $grey-blue-900;
  font-size: 30px;
  font-family: $font-poppins-bold;
  letter-spacing: 1.2px;
  line-height: normal;
}

/* Buttons */
a:hover {
  text-decoration: none;
}

/* Content */

#content1 {
  width: 100%;
  min-height: 100vh;
  transition: all 0.22s;
  padding: 0;
  background-color: #f7fbff;
}

/* Inputs */

.input-form-input {
  position: relative;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  outline: none;
  font-family: $font-poppins-regular;
  font-size: 15px;
  letter-spacing: 0.4px;
  border: 1px solid $accent-blue-200;
  border-radius: 6px;
}

.input-form-input::placeholder {
  color: #b7c3ce;
}

.input-form-input:focus {
  background: #f0f7ff;
}

/* Colored background */
.colored-bg {
  border-radius: 10px;
  background-color: #cce4ff;
  padding: 30px 0;
  position: relative;
}

.error-message {
  color: $validate-msg-color;
  font-family: $font-poppins-regular;
  font-size: 13px;
  position: absolute;
  top: calc(100% - 25px);
  left: 0;
  &.left {
    left: 15px;
  }
  &.seriecover {
    left: 9px;
    font-size: 13px;
  }
}

.modal-body {
  .invalid-message {
    position: unset;
  }
}

.form-logins-b {
  .error-message {
    left: 0;
    bottom: -10px;
  }
}

/* Pagination */
.pagination-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  .pagination-results {
    font-size: 14px;
    font-family: $font-poppins-regular;
    color: #6c767e;
    place-self: center;
  }

  .pagination {
    margin: 0;
    align-items: center;

    li {
      margin: 0 3px !important;
      color: $grey-blue-750;
      border-radius: 4px;

      &.page-item {
        .page-link {
          background-color: transparent;
          border: none;
          color: $grey-blue-750;
          padding: 10px !important;
          font-size: 16px;
          line-height: 16px;
          font-family: $font-poppins-medium;

          &:focus {
            box-shadow: none;
          }
          &:hover {
            box-shadow: none;
          }
          &:active {
            box-shadow: none;
          }
        }

        &.active {
          background: $grey-blue-900;
          transition: 0.3s;

          .page-link {
            color: $white;
          }
        }

        &:hover {
          background: $grey-blue-900;
          transition: 0.3s;

          .page-link {
            color: $white;

            .icon {
              background: $white;
            }
          }
        }
      }

      &.prev-item,
      &.next-item {
        .page-link {
          padding: 8px 5px !important;
          display: flex;

          .icon {
            mask-size: 8px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

/* Text editor */
.tox {
  &.tox-tinymce {
    width: 100% !important;
    border-radius: 6px;
    border: 1px solid $accent-blue-500;

    .tox-editor-container {
      .tox-editor-header {
        box-shadow: none;
        padding: 0;
      }
    }
  }
  .tox-statusbar {
    border-top: 1px solid $accent-blue-500 !important;
    .tox-statusbar__branding {
      display: none;
    }
  }
  .tox-menubar + .toxtoolbar {
    border-top: 1px solid $accent-blue-500 !important;
    border-bottom: 1px solid $accent-blue-500 !important;
    background: none;
  }
  .tox-menubar + .tox-toolbar-overlord {
    .tox-toolbar__primary {
      border-top: 1px solid $accent-blue-500 !important;
      border-bottom: 1px solid $accent-blue-500 !important;
      background: none;
    }
  }
  &:not([dir="rtl"]) {
    .tox-toolbar__group:not(:last-of-type) {
      border-right: 1px solid $accent-blue-500 !important;
    }
  }
  &:hover {
    border: 1px solid $accent-blue-100;
    box-shadow: 0px 0px 0px 1px $accent-blue-100;
    transition: 0.3s;
  }
  &:focus {
    border: 1px solid $accent-blue-100;
    box-shadow: none;
    transition: 0.3s;
  }
}

/* custom image upload blog */

.file-upload1 {
  .image-upload-wrap1 {
    position: relative;
    border-radius: 4px;
    outline: none;
    border: 1px solid $accent-blue-500;
    &:hover {
      border: 1px solid $accent-blue-100;
      box-shadow: 0px 0px 0px 1px $accent-blue-100;
      transition: 0.3s;
    }
    &:focus {
      border: 1px solid $accent-blue-100;
      box-shadow: none;
      transition: 0.3s;
    }
    .file-upload-input1 {
      position: absolute;
      right: 0;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      appearance: none;
      cursor: pointer;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .drag-text1 {
      h3 {
        padding: 10px 17px;
        color: $placeholder-color;
        font-family: $font-poppins-regular;
        font-size: 1rem;
        margin: 0;
        cursor: pointer;
        text-align: left;
        &::placeholder {
          color: $placeholder-color;
          font-family: $font-poppins-regular;
        }
        img {
          position: absolute;
          right: 10px;
          top: 7px;
        }
        div {
          span {
            color: #332e2c;
          }
        }
        .remove-marc {
          color: #ff0000;
          font-size: 24px;
          font-weight: bold;
          outline: none;
          line-height: 20px;
        }
      }
      .upload {
        margin-right: 10px;
        font-size: 30px;
        color: $accent-blue-200;
      }
    }
    &.form-error {
      .drag-text1 {
        .upload {
          color: #e81d40;
        }
      }
    }
  }
}

.file-upload {
  .file-upload-content {
    text-align: center;
    .file-upload-image {
      max-height: 200px;
      max-width: 200px;
      float: left;
    }
    .remove-image {
      margin: 0;
      vertical-align: top;
      background: none;
      padding: 0;
      color: #ff0000;
      font-size: 27px;
      font-weight: bold;
      outline: none;
      line-height: 20px;
      border: none;
    }
  }
}

/* Select */
.select_input__control {
  cursor: pointer;
}
.select_input_menu {
  cursor: pointer;
}

.select_input__option {
  cursor: pointer !important;
}

.select_input__indicator {
  cursor: pointer;
}

.select_input__value-container {
  cursor: pointer;
}

.select_input__control--menu-is-open {
  .select_input__indicator {
    transform: rotate(180deg);
  }
}

/* Choose export marc */
.choose_export {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: -12px;
      left: 8px;
      width: 5px;
      height: 12px;
      border: solid #007aff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  label {
    position: relative;
    cursor: pointer;
    font-size: 0px;
    width: 25px;
    margin: 0;
    &:before {
      content: "";
      appearance: none;
      background-color: transparent;
      border: 2px solid #007aff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      border-radius: 6px;
      display: inline-block;
      position: absolute;
      top: -15px;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
    }
    &.choose_all {
      color: white;
      font-family: "Galano Grotesque SemiBold", sans-serif;
      margin-left: 15px;
      &:before {
        border-color: #fff;
      }
      &:checked + label:after {
        border-color: #fff;
      }
    }
  }
}

/*confirmation huazo*/

.member-details-bg {
  background-color: $grey-blue-400;
  border-radius: 6px;
  padding: 50px;
  color: $grey-blue-750;
  font-family: $font-poppins-regular;
  font-size: 14px;
}
.list-data {
  ul {
    li {
      color: $grey-blue-750;
      font-size: 16px;
      font-family: $font-poppins-regular;
      padding: 0 0 10px;
    }
  }
}
/*custom radio buttons*/
input[type="radio"] {
  padding: 0;
  border: 2px solid #6bb9fc;
  -webkit-appearance: inherit;
  appearance: inherit;
  border-radius: 50%;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-top: 0;
}
input[type="radio"]:checked {
  background: radial-gradient(#007aff 37%, white 45%);
}
input[type="radio"]:not(:checked) {
  background: white;
}

.radio-buttons {
  display: flex;
  .radio-button {
    display: flex;
    position: relative;
    align-items: center;

    input[type="radio"] {
      right: 0;
      left: auto;
      margin: 0 !important;
      border: 1px solid $accent-blue-500;
      box-shadow: none;
      padding: 0;
      -webkit-appearance: inherit;
      appearance: inherit;
      border-radius: 50%;
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      cursor: pointer;

      &:checked {
        background: radial-gradient($accent-blue-100 37%, $white 45%);
      }
      &:not(:checked) {
        background: $white;
      }
      &:hover {
        border: 1px solid $accent-blue-100;
        box-shadow: 0px 0px 0px 1px $accent-blue-100;
        transition: 0.3s;
      }
      &:focus {
        border: 1px solid $accent-blue-100;
        box-shadow: none;
        transition: 0.3s;
      }
    }
    label {
      padding-right: 10px;
      font-family: $font-poppins-regular;
      margin-bottom: 0;
    }
  }
  &.borrow {
    margin-bottom: 0;
    .radio-button {
      label {
        padding-right: 0;
        padding-left: 30px;
      }
      input[type="radio"] {
        margin-left: 0;
        left: 0;
      }
    }
  }
}

.calendar-block {
  position: relative;
  .form-input {
    display: block;
    width: 100%;
    padding: 7px 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $grey-blue-900;
    background-color: $white;
    background-clip: padding-box;
    font-family: $font-poppins-regular;
    border: 1px solid $accent-blue-500;
    appearance: none;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid $accent-blue-100;
      box-shadow: 0px 0px 0px 1px $accent-blue-100;
      transition: 0.3s;
    }

    &:focus {
      border: 1px solid $accent-blue-100;
      box-shadow: none;
      transition: 0.3s;
    }

    &::placeholder {
      color: $placeholder-color;
      font-family: $font-poppins-regular;
    }
  }
  .input-container {
    content: url(../images/icons/date-icon.svg);
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
  }
}

.datePickerTrigger-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  pointer-events: none;
  padding: 5px 10px 5px 5px;
  background: #f0f7ff;
}

.list-data {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      color: #6c767e;
      font-size: 16px;
      font-family: $font-poppins-regular;
      padding: 0 0 10px;
      &.member-detail {
        font-size: 20px;
      }
    }
  }
}

.textpicker {
  background: #f0f7ff;
  cursor: pointer;
}

/* Show Serial */

.serie_info {
  .table-acc {
    width: 100%;
    padding: 0 15px;
    .table-header-acc {
      padding: 10px;
      background: $grey-blue-900;
      border-radius: 1px;
      text-align: center;
      color: $white;
      text-transform: uppercase;
      display: flex;
    }
    .table-list-acc {
      width: calc(100% - 0px);
      margin: 0 auto;
      .no-side-borders {
        border: none;
        background-color: $grey-blue-400;
        border-radius: 0;
        .accordion-item {
          border: none;
          border-radius: 0;
          background-color: $grey-blue-400;
          border-bottom: 1px solid $table-border;
        }
        .items-centered {
          display: flex;
          flex-wrap: wrap;
          &:hover {
            background-color: $white;
            cursor: pointer;
          }
          &:active {
            background-color: $white;
            cursor: pointer;
          }
          > div {
            font-size: 15px;
            font-family: $font-poppins-regular;
            text-align: center;
            color: #6c767e;
          }
        }
        .series-div {
          .image-holder {
            width: 200px;
            text-align: center;
            img {
              height: 150px;
              width: 100px;
              object-fit: cover;
            }
          }
          .info-acc {
            width: calc(90% - 200px);
            margin: auto;
            margin-right: 100px;
            .item-attributes-summary {
              background-color: $white;
              border: 1px solid $grey-blue-900;
              border-radius: 6px;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              padding: 15px 0;
              margin-bottom: 15px;
              > div > div {
                color: #6c767e;
                font-family: $font-poppins-regular;
                font-size: 14px;
                margin-bottom: 10px;
              }
              > div > div:last-of-type {
                margin-bottom: 0;
              }
              .cancel {
                width: 10px;
                height: 10px;
                background-color: $red-color;
                margin-top: 5px;
              }
              .approve {
                background-color: $green;
                width: 10px;
                height: 10px;
                margin-top: 5px;
              }
            }
            .serie-copy-title {
              font-size: 20px;
              font-family: $font-poppins-semibold;
              color: $grey-blue-900;
              margin-bottom: 20px;
            }
            .item-available {
              border: 1px solid #55ab64;
              border-radius: 3px;
              color: #55ab64;
              font-family: $font-poppins-regular;
              padding-left: 2px;
              padding-right: 2px;
            }
            .item-borrowed {
              border: 1px solid #e81d40;
              border-radius: 3px;
              color: #e81d40;
              font-family: $font-poppins-regular;
              padding-left: 2px;
              padding-right: 2px;
            }
            .item-pending {
              border: 1px solid #ada60e;
              border-radius: 3px;
              color: #ada60e;
              font-family: $font-poppins-regular;
              padding-left: 2px;
              padding-right: 2px;
            }
            .item-reserved {
              border: 1px solid #ffae00;
              border-radius: 3px;
              color: #ffae00;
              font-family: $font-poppins-regular;
              padding-left: 2px;
              padding-right: 2px;
            }
          }
        }
      }
    }
  }
  > form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.no-results {
  width: 100%;
  text-align: center;
  padding: 15px;
  font-family: "Poppins-Medium";
  font-size: 18px;
  color: #1c2b4b;
}

.series-items-d {
  background-color: #f0f7ff;
  margin-bottom: 30px;
  border: 1.5px solid #6bb9fc;
  border-radius: 8px;
  padding: 10px;
}

.serie-item-block {
  &:last-of-type {
    .show-hide-btns {
      .addFields {
        display: block;
      }
    }
  }
  &:only-of-type {
    .show-hide-btns {
      .removeFields {
        display: none;
      }
    }
  }
  .show-hide-btns {
    .addFields {
      display: none;
    }
  }
  .series-items {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

/* Biblography add items */
.bibliography_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .series-items-block {
    .series-items {
      background-color: transparent;
      border: none;
      padding-bottom: 30px;
      border-bottom: 1px solid #6bb9fc;
      border-radius: 0;
    }
  }
}

/* Edit Serie */
.edit-serie {
  .cover-img {
    width: 150px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: white;
      border: 1px solid #6bb9fc;
      border-radius: 6px;
    }
  }
  .serie-btns {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
  }
  .info-serie {
    display: flex;
    flex-direction: column;
    span {
      color: #6c767e;
      font-size: 18px;
      font-family: "Galano Grotesque Regular", sans-serif;
      word-break: break-all;
    }
  }
  .item-btns {
    display: flex;
    flex-direction: column;
  }
  .serie_add_items {
    .submit_add_items {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

/* serie info */

.serie-info {
  .series-items {
    margin: 0;
    .serie-item-info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 15px 1px;
      > div > div {
        color: #6c767e;
        font-family: "Galano Grotesque Regular";
        font-size: 14px;
        margin-bottom: 10px;
      }
      > div > div:last-of-type {
        margin-bottom: 0;
      }
      .delete-item {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }
  }
}

/* Tooltip */
.show {
  &.type-dark[data-id="tooltip"] {
    background: rgba(0, 0, 0, 0.75);
    font-family: $font-poppins-regular;
    border-radius: 4px;
    color: $white;
    z-index: 1 !important;
    opacity: 1 !important;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;
    text-transform: none;
    font-weight: normal;
    max-width: 300px;
    word-wrap: break-word;
  }
  &.place-bottom {
    &.type-dark[data-id="tooltip"]::before {
      content: none;
    }
    &.type-dark[data-id="tooltip"]::after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid rgba(0, 0, 0, 0.75);
      background: none;
      transform: rotate(0deg);
      top: -11px;
    }
  }
  &.place-top {
    &.type-dark[data-id="tooltip"]::before {
      content: none;
    }
    &.type-dark[data-id="tooltip"]::after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(0, 0, 0, 0.75);
      background: none;
      transform: rotate(0deg);
      bottom: -11px;
    }
  }
  &.place-right {
    &.type-dark[data-id="tooltip"]::before {
      content: none;
    }
    &.type-dark[data-id="tooltip"]::after {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid rgba(0, 0, 0, 0.75);
      background: none;
      transform: rotate(0deg);
      left: -11px;
    }
  }
  &.place-left {
    &.type-dark[data-id="tooltip"]::before {
      content: none;
    }
    &.type-dark[data-id="tooltip"]::after {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid rgba(0, 0, 0, 0.75);
      background: none;
      transform: rotate(0deg);
      right: -11px;
    }
  }
}

/* Create Biblioitem */

.file_upload_div {
  position: relative;
  outline: none;
  .file_upload_input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  .upload-button {
    background-color: #6c767e;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    box-shadow: none;
    color: #fff;
    border-radius: 6px;
    outline: none;
    text-align: center;
    font-family: "Galano Grotesque Regular";
    padding: 10px;
    letter-spacing: 1.2px;
    margin: 0;
    cursor: pointer;
  }
  &:hover {
    .upload-button {
      background-color: #006de6;
    }
  }
}

.uploaded_file_div {
  .uploaded_file {
    word-break: break-all;
    width: calc(100% - 40px);
    font-family: "Galano Grotesque Medium";
  }
  .remove-image {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    vertical-align: top;
    background: none;
    padding: 0;
    color: #ff0000;
    font-size: 27px;
    font-weight: bold;
    outline: none;
    line-height: 20px;
    width: 30px;
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: auto;
  span {
    margin: 0 15px;
    color: #002c3e;
    font-size: 30px;
    font-family: "Galano Grotesque SemiBold", sans-serif;
    letter-spacing: 1.2px;
  }
  .button {
    margin-top: 7px;
  }
}

.navigation-bibliography {
  display: flex;
  align-items: center;

  span {
    background: $grey-blue-400;
    height: 40px;

    &.left-arrow {
      border: 1px solid $grey-blue-700;
      border-right: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      display: flex;
      align-items: center;
      padding: 5px;

      .left-arrow-icon {
        mask-size: 12px;
      }

      &:hover {
        background: $grey-blue-600;
        cursor: pointer;
        transition: 0.3s;
      }

      &:active {
        background: $grey-blue-700;
        transition: 0.3s;
      }
    }

    &.current-step {
      font-size: 18px;
      line-height: 24px;
      font-family: "Poppins-Medium";
      display: flex;
      align-items: center;
      border-top: 1px solid $grey-blue-700;
      border-bottom: 1px solid $grey-blue-700;
      padding: 0 6px;
      color: $grey-blue-750;
    }

    &.right-arrow {
      border: 1px solid $grey-blue-700;
      border-left: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      align-items: center;
      padding: 5px;

      .right-arrow-icon {
        mask-size: 12px;
      }

      &:hover {
        background: $grey-blue-600;
        cursor: pointer;
        transition: 0.3s;
      }

      &:active {
        background: $grey-blue-700;
        transition: 0.3s;
      }
    }

    &.disabled {
      i {
        opacity: 0.5 !important;
      }

      &:hover {
        background: $grey-blue-400;
        cursor: auto;
      }
    }
  }
}

#toast-container {
  &.toast-bottom-center {
    > div {
      width: 100% !important;
      max-width: 650px;
    }
  }
  &.toast-top-center {
    > div {
      width: 100% !important;
      max-width: 650px;
    }
  }
}

.toast-close-button {
  top: -3px;
}

/* Media Queries */

@media (max-width: 1500px) {
  .small-offset-left {
    padding: 0 70px;
  }
}

@media (max-width: 1200px) {
  .small-offset-left {
    padding: 0 50px;
  }
  .serie_info {
    .table-acc {
      .table-list-acc {
        .no-side-borders {
          .series-div {
            .image-holder {
              width: 150px;
            }
            .info-acc {
              width: calc(95% - 150px);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1434px) {
  .serie_info {
    .table-acc {
      .table-list-acc {
        .no-side-borders {
          .series-div {
            .image-holder {
              width: 150px;
              margin: auto;
            }
            .info-acc {
              width: calc(95% - 150px);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .serie_info {
    > form {
      > * {
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .partial-title {
    font-size: 24px;
    margin-bottom: 40px !important;
  }

  .serie_info {
    .table-acc {
      .table-header-acc {
        display: none;
      }
      .table-list-acc {
        width: calc(100% + 100px);
        margin-left: -50px;
        border-top: 1px solid #002c3e;
        border-bottom: 1px solid #002c3e;
        .no-side-borders {
          padding: 0 50px;
          .items-centered {
            > div {
              padding: 5px 0 5px 40% !important;
              position: relative;
              text-align: right !important;
              border-bottom: none;
              word-wrap: break-word;
              margin: auto;
            }
            > div:before {
              content: attr(data-label);
              float: left;
              text-transform: uppercase;
              font-size: 16px;
              font-family: "Galano Grotesque Regular", sans-serif;
              color: #6c767e;
              padding-right: 30px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .series-div {
            .info-acc {
              .item-attributes-summary {
                > div > div:last-of-type {
                  margin-bottom: 10px;
                }
                > div {
                  margin-bottom: 10px;
                }
                > div:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
          &:last-of-type {
            border-bottom: none;
          }
          &:hover {
            background-color: #dcedff;
          }
        }
      }
    }
    .series-items {
      .serie-item-info {
        > div > div:last-of-type {
          margin-bottom: 15px;
        }
        > div > div {
          margin-bottom: 5px;
        }
        > div:last-of-type > div:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .edit-serie {
    .item-btns {
      padding: 0;
      margin-top: 15px;
    }
    .serie_add_items {
      .submit_add_items {
        position: relative;
        margin-top: 15px;
      }
    }
  }

  /* Borrow */
  .search-sub-btn {
    order: 3;
  }
  .small_btn {
    padding: 4px 10px;
    min-height: auto;
  }

  .member-details-bg {
    padding: 25px;
  }

  .member-details-bg * {
    padding-right: 0;
  }
}

@media (max-width: 576px) {
  .small-offset-left {
    padding: 0 30px;
  }

  .serie_info {
    .table-acc {
      .table-list-acc {
        .no-side-borders {
          .series-div {
            .image-holder {
              width: 100%;
            }
            .info-acc {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .edit-serie {
    .info-serie {
      padding: 0;
      margin-top: 16px;
    }
  }

  /* Pagination */

  .pagination-block {
    .select-page {
      .pagination {
        flex-wrap: wrap;
        li {
          margin: 0 3px !important;
          &.page-item {
            .page-link {
              padding: 0.4rem 0.3rem !important;
            }
          }
        }
      }
    }
  }
}
/* zr950 checkbox */
.choose_option input[type="checkbox"] {
  padding: 0;
  border: 2px solid #6bb9fc;
  -webkit-appearance: inherit;
  appearance: inherit;
  border-radius: 50%;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-top: 0;
}
input[type="checkbox"]:checked {
  background: radial-gradient(#007aff 37%, white 45%);
}
input[type="checkbox"]:not(:checked) {
  background: white;
}

.lined-title {
  border-bottom: 1px solid $accent-blue-100;
  height: 1px;
  line-height: 0.1em;
  margin: 7px 0;
  width: 100%;
  span {
    background: $white;
    color: #6c767e;
    font-family: $font-poppins-semibold;
    font-size: 16px;
    padding: 0 15px 0 0;
  }
}

.search--member {
  max-width: 70%;
  margin: auto;
}

@media (max-width: 1600px) {
  .search--member {
    max-width: 85%;
  }
}

@media (max-width: 1350px) {
  .search--member {
    max-width: 100%;
  }
}

.chart-title {
  font-family: $font-poppins-medium;
  font-size: 20px;
  letter-spacing: 0.5px;
  color: $grey-blue-900;
}

.serial-inputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .serial-text {
    box-sizing: border-box;
  }
}

.tutorial {
  @media (max-width: 992px) {
    display: none;
  }
}

.language {
  @media (max-width: 992px) {
    display: none !important;
  }
}

.video-tutorial {
  width: 90%;

  @media (max-width: 1200px) {
    width: 100%;
  }
}
