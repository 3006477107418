@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../fonts/Poppins-ExtraBold.eot");
  src: url("../fonts/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBold.woff") format("woff"),
    url("../fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins-Bold.eot");
  src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff"),
    url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/Poppins-Light.eot");
  src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff"),
    url("../fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("../fonts/Poppins-Black.eot");
  src: url("../fonts/Poppins-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Black.woff2") format("woff2"),
    url("../fonts/Poppins-Black.woff") format("woff"),
    url("../fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("../fonts/Poppins-Thin.eot");
  src: url("../fonts/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Thin.woff2") format("woff2"),
    url("../fonts/Poppins-Thin.woff") format("woff"),
    url("../fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("../fonts/Poppins-ExtraLight.eot");
  src: url("../fonts/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLight.woff") format("woff"),
    url("../fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.eot");
  src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
