/** @format */

.form-bg-col-blue {
  border-radius: 10px;
  background-color: #cce4ff;
}

.modal-backdrop {
  &.show {
    background-color: #1c2b4b4d;
  }
}

.modal-content {
  border-radius: 6px !important;
  border: none;

  .modal-header {
    border: none;
    padding: 20px;
    background: $grey-blue-900;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .modal-title {
      color: $white;
      font-size: 16px;
      font-family: $font-poppins-regular;
    }

    .btn-close {
      background: none;
      mask-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.64049 4L7.86134 0.160736C7.91533 0.0969326 7.86993 0 7.7865 0H6.80736C6.74969 0 6.69447 0.0257668 6.65644 0.0699386L4 3.23681L1.34355 0.0699386C1.30674 0.0257668 1.25153 0 1.19263 0H0.213492C0.130057 0 0.0846578 0.0969326 0.138646 0.160736L3.3595 4L0.138646 7.83926C0.126552 7.85348 0.118793 7.87088 0.116291 7.88938C0.113788 7.90788 0.116646 7.92671 0.124527 7.94363C0.132407 7.96055 0.144979 7.97486 0.160749 7.98485C0.176518 7.99484 0.194824 8.0001 0.213492 8H1.19263C1.2503 8 1.30552 7.97423 1.34355 7.93006L4 4.76319L6.65644 7.93006C6.69325 7.97423 6.74846 8 6.80736 8H7.7865C7.86993 8 7.91533 7.90307 7.86134 7.83926L4.64049 4Z' fill='%23ED0606'/%3E%3C/svg%3E%0A");
      width: 15px;
      height: 15px;
      display: inline-block;
      mask-repeat: no-repeat;
      mask-size: 13px;
      mask-position: center;
      transition: background-color 200ms;
      background-color: $white;
      opacity: 1;
      padding: 3px;
    }
  }

  .modal-body {
    padding: 20px;

    &.view-file {
      display: flex;
      flex-direction: column;
      gap: 7px;

      > div {
        .title {
          color: $grey-blue-750;
          font-family: $font-poppins-medium;
          font-weight: normal;
          font-size: 14px;
          text-transform: uppercase;
        }

        .value {
          color: $grey-blue-750;
          font-size: 15px;
          font-family: $font-poppins-regular;
          font-weight: normal;
          word-break: break-word;
        }

        .tags-container {
          display: flex;
          gap: 7px;

          .tag {
            font-family: $font-poppins-regular;
            line-height: 21px;
            background: $accent-blue-25;
            border: 1px solid $accent-blue-500;
            border-radius: 4px;
            padding: 3px;
            color: $accent-blue-100;
            font-size: 13px;
          }
        }
      }
    }
  }

  .modal-footer {
    border: none;
    padding: 0 20px 20px;
  }
}

.add-item {
  background: $grey-blue-400;
  border: 1px solid $grey-blue-500;
  border-radius: 10px;
}

.nav-item {
  .button {
    background-color: white;
    border: none;
  }
}

.tooltip-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.title-t {
  .tooltip-text {
    max-width: 100%;
  }
}

#report-stats {
  .tr-report-stats {
    td {
      padding-top: 15px;
      padding-bottom: 15px;
      vertical-align: middle;
      cursor: pointer;
    }
    p {
      font-family: $font-poppins-regular;
      margin-bottom: 0;
    }
  }
}

.nav-tabs {
  border: none;
  overflow: hidden;

  .nav-link {
    text-align: center;
    color: $grey-blue-900;
    font-family: $font-poppins-regular;
    text-transform: uppercase;
    height: 100%;
    border: none;
    border-radius: 0;
    cursor: pointer;

    &.active {
      background: transparent;
      border: none;
      border-radius: 0;
      color: $white;
      font-family: $font-poppins-medium;
      width: 100%;
      height: 100%;
    }
  }
  .nav-item {
    border: 1px solid $grey-blue-700;

    &:first-of-type {
      border-radius: 4px 0px 0px 4px;
      overflow: hidden;
    }
    &:last-of-type {
      border-radius: 0px 4px 4px 0px;
      overflow: hidden;
    }

    &.active {
      border-color: $grey-blue-900;
      background: $grey-blue-900;
    }

    &:hover {
      border-color: $grey-blue-700;
      border-top-color: $grey-blue-900;
      border-bottom-color: $grey-blue-900;
      transition: 0.3s;

      .nav-link {
        background: $grey-blue-900;
        color: $white;
      }
    }
  }

  @media (max-width: 767px) {
    gap: 5px;

    .nav-item {
      border-radius: 4px !important;

      &:hover {
        border-color: $grey-blue-900 !important;
      }
    }
  }
}

.tab-content-bg {
  background: $grey-blue-400;
  border-radius: 4px;
  margin-top: 7px;
  padding: 35px;

  @media (max-width: 480px) {
    padding: 20px;
  }
}

.show-material {
  .image-holder {
    margin-top: 0;

    img {
      max-width: 340px;
    }
  }

  .description-holder {
    > * {
      margin-bottom: 0;
    }

    &.marc {
      .name-desc {
        border: none;
        padding-bottom: 0;

        @media (max-width: 480px) {
          width: 100% !important;
        }
      }
    }

    .marc-info {
      display: flex;
      gap: 5px;

      .name-item-a {
        font-family: $font-poppins-bold;
        font-size: 14px;
        line-height: 21px;
        color: $grey-blue-750;
      }

      .name-item {
        padding-bottom: 0;
      }

      @media (max-width: 480px) {
        width: 100% !important;
      }
    }

    .name-desc {
      font-family: $font-poppins-medium;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: $grey-blue-750;
      padding-bottom: 10px;
      border-right: 1px solid $grey-blue-500;
    }

    .name-item {
      gap: 5px;
      display: flex;
      flex-wrap: wrap;
      font-family: $font-poppins-regular;
      font-size: 14px;
      line-height: 21px;
      color: $grey-blue-750;
      padding-bottom: 10px;

      .tag {
        background: $accent-blue-25;
        border: 1px solid $accent-blue-500;
        border-radius: 4px;
        padding: 3px;
        color: $accent-blue-100;
        font-size: 13px;
      }
    }

    @media (max-width: 767px) {
      .name-desc {
        border: none;
        padding-bottom: 0;
        text-align: left;
      }

      .name-item {
        padding-bottom: 15px;
      }
    }
  }
}

.red-tag {
  background: $red-tag-bg;
  border: 1px solid $red-tag-border-color;
  border-radius: 2px;
  color: $red-tag-color;
  font-family: $font-poppins-regular;
  font-size: 14px;
  padding: 2px 5px;
  line-height: 16px;
}

.green-tag {
  background: $green-tag-bg;
  border: 1px solid $green-tag-border-color;
  border-radius: 2px;
  color: $green-tag-color;
  font-family: $font-poppins-regular;
  font-size: 14px;
  padding: 2px 5px;
  line-height: 16px;
}

.blue-tag {
  background: $accent-blue-25;
  border: 1px solid $accent-blue-500;
  border-radius: 2px;
  color: $accent-blue-100;
  font-family: $font-poppins-regular;
  font-size: 14px;
  padding: 2px 5px;
  line-height: 16px;
}

/*member */
.invite-member {
  .col-form {
    > div {
      position: relative;
    }
  }
}

.tooltip-title {
  color: #007aff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  max-width: 200px;
  &:hover {
    text-decoration: none;
  }
}

.specimen-data {
  .specimen-item {
    width: 75%;
    margin-bottom: 40px;

    .item-attributes-summary {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      padding: 10px 15px;
      margin-bottom: 10px;
      background: $grey-blue-400;
      border: 1px solid $table-border;
      border-radius: 6px;

      .item-row {
        display: flex;
        column-gap: 4px;
        flex-wrap: wrap;

        .item-title {
          font-family: $font-poppins-medium;
          font-size: 15px;
          line-height: 20px;
          color: $grey-blue-750;
        }

        .item-value {
          font-family: $font-poppins-regular;
          font-size: 15px;
          line-height: 20px;
          color: $grey-blue-750;
        }
      }

      > div {
        row-gap: 5px;
        display: flex;
        flex-direction: column;
      }
    }

    .item-action {
      display: flex;
      justify-content: flex-end;
      column-gap: 15px;
    }

    @media (max-width: 1280px) {
      width: 100%;
    }
  }

  .nothing-to-show {
    font-size: 16px;
    font-family: $font-poppins-medium;
    color: $grey-blue-750;
  }
}

.editbiblioitem {
  .accordion_item {
    margin-bottom: 25px;

    .acc-item {
      display: flex;
      justify-content: space-between;
      gap: 12px;

      .acc_button {
        width: 100%;
        background: $grey-blue-900;
        border: none;
        border-radius: 4px;
        text-align: left;
        padding: 7px 20px;
        font-family: "Poppins-Medium";
        font-size: 16px;
        line-height: 24px;
        color: $white;
      }
      .acc_icons {
        background: $grey-blue-900;
        padding: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;

        i {
          transition: 0.3s;
          transform: rotate(0deg);
        }
      }
    }

    &.active {
      .acc-item {
        .acc_icons {
          i {
            transition: 0.3s;
            transform: rotate(180deg);
          }
        }
      }
    }

    .content {
      margin: 20px 0;
    }
  }
}

.item-available {
  border: 1px solid #55ab64;
  border-radius: 3px;
  color: #55ab64;
  font-family: "Galano Grotesque Regular";
}

.item-borrowed {
  border: 1px solid #e81d40;
  border-radius: 3px;
  color: #e81d40;
  font-family: "Galano Grotesque Regular";
}

.item-reserved {
  border: 1px solid #ffae00;
  border-radius: 3px;
  color: #ffae00;
  font-family: "Galano Grotesque Regular";
}
.item-pending {
  border: 1px solid #ada60e;
  border-radius: 3px;
  color: #ada60e;
  font-family: "Galano Grotesque Regular";
}

.media-tag {
  padding: 3px 5px;
  border: 1px solid #007aff;
  border-radius: 6px;
  color: #007aff;
  font-family: "Galano Grotesque Light", sans-serif;
  font-size: 14px;
  display: inline-block;
  margin: 0 5px 5px 0;
}

.modal-button {
  position: relative;
  min-height: 40px;
  border: 2px solid #6c767e;
  border-radius: 6px;
  background-color: #6c767e;
  width: fit-content;
  color: #fff;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.2s;
  padding: 9px 15px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  &:hover {
    background-color: #006de6;
    border-color: #006de6;
    color: #fff !important;
    transition: 0.4s;
    outline: none;
    text-decoration: none;
  }
  &:active {
    background-color: #006de6;
    border-color: #006de6;
    color: #fff !important;
    transition: 0.4s;
    outline: none;
    text-decoration: none;
  }
  &:focus {
    background-color: #006de6;
    border-color: #006de6;
    color: #fff !important;
    transition: 0.4s;
    outline: none;
    text-decoration: none;
  }
}

#create-biblio {
  .tag-container-fake-input {
    background-color: white;
    vertical-align: middle;
    border: 2px solid #6bb9fc;
    border-radius: 6px;
    padding: 20px 10px 13px;
    min-height: 73px;
  }
}
#create-item {
  .tag-container-fake-input {
    background-color: white;
    vertical-align: middle;
    border: 2px solid #6bb9fc;
    border-radius: 6px;
    padding: 20px 10px 13px;
    min-height: 73px;
  }
}
#create-biblio-item {
  .tag-container-fake-input {
    background-color: white;
    vertical-align: middle;
    border: 2px solid #6bb9fc;
    border-radius: 6px;
    padding: 20px 10px 13px;
    min-height: 73px;
  }
}
.confirm-marc-records {
  .tag-container-fake-input {
    background-color: white;
    vertical-align: middle;
    border: 2px solid #6bb9fc;
    border-radius: 6px;
    padding: 20px 10px 13px;
    min-height: 73px;
  }
}

#create-biblio {
  .tage {
    min-height: 80px;
  }
}

#create-item {
  .tage {
    min-height: 80px;
  }
}
#create-biblio-item {
  .tage {
    min-height: 80px;
  }
}
.confirm-marc-records {
  .tage {
    min-height: 80px;
  }
}

.forward {
  background-position-y: 0%;
  background-size: auto;
  background-size: contain;
  background-position-y: bottom;
  height: 20px;
  margin-left: 5px;
  padding-top: 5px;
}

.file-upload-box {
  position: relative;
  .file-upload-wrap-box {
    .file-upload-input1 {
      position: absolute;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
      display: none;
    }
    .file-pdf-input {
      position: absolute;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
      display: none;
    }
  }
  .file-upload-wrap-box-pdf {
    .file-upload-input1 {
      position: absolute;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
      display: none;
    }
    .file-pdf-input {
      position: absolute;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
      display: none;
    }
  }
}

.upload-button-pdf {
  background-color: #6c767e;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 7px;
  box-shadow: none;
  color: #fff;
  border-radius: 6px;
  outline: none;
  text-align: center;
  font-family: "Galano Grotesque Regular";
  padding: 10px;
  display: none;
  letter-spacing: 1.2px;
  margin: 0;
}

.downshift-list {
  box-shadow: 0px 0px 4px rgb(83 124 196 / 30%);
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  margin: 7px 0 0;
  border: none;
  border-bottom: none;
  overflow-y: scroll;
  max-height: 300px;
  width: 100%;
  padding: 0px;
  position: absolute;
  z-index: 999;

  .downshift-item {
    padding: 8px 12px;
    cursor: pointer;
    border: none;
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    color: $grey-blue-750;
    font-family: $font-poppins-regular;
    font-size: 15px;

    &:hover {
      background-color: #214b9e;
      color: #fff;
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #007aff;
  }
  &::-webkit-scrollbar-thumb {
    background: #007aff;
    border-radius: 6px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 2px;
    background: #fff;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.btn-unstyled {
  &.emptyState {
    position: absolute;
    right: 13px;
    top: 7px;
    font-weight: 600;
    color: #ff0000;
  }
}

#author-live-search-results {
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-top: 1rem;
  padding: 0 10px;
  overflow: auto;
  height: 150px;
  width: 100%;
}
.border {
  border: 2px solid #6bb9fc;
  border-radius: 6px;
}

// Modal list
.data-list {
  height: 130px;
  margin: 0;
  padding: 3px 0;
  border: 1px solid $accent-blue-500;
  border-radius: 4px;
  overflow-y: auto;

  li {
    padding: 5px 15px;
    font-size: 14px;
    font-family: $font-poppins-regular;
    color: $black;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-bg-color;
  }
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-bg-color;
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-bg-color;
    border-radius: 4px;
  }
}

.tag-kyce {
  border: 1px solid #6bb9fc;
  border-radius: 4px;
  color: #6bb9fc;
  background-color: #f0f7ff;
  padding: 3px;
  margin-bottom: 7px;
  display: inline-block;
  font-size: 14px;
  font-family: "Galano Grotesque Regular";
}

.pointer-li {
  cursor: pointer;
}

.x-button {
  color: red;
}

.tag-container-fake-input {
  background-color: white;
  vertical-align: middle;
  border: 2px solid #6bb9fc;
  border-radius: 6px;
  padding: 20px 10px 13px;
  min-height: 73px;
}

.removeFields {
  bottom: 0px;
  float: right;
  width: 50px !important;
}

.addFields {
  bottom: 0px;
  float: right;
  width: 50px !important;
  color: white !important;
}

.new-item-buttons {
  width: 100% !important;
}

.forward1 {
  background-size: contain;
  background-position-y: bottom;
  height: 17px;
  margin-left: 5px;
  padding-bottom: 3px;
}

.forward2 {
  background-size: contain;
  background-position-y: bottom;
  height: 17px;
  margin-left: 5px;
  padding-bottom: 1px;
}

.language-switch.show {
  padding: 5px 0;
  transform: translate(0px, 5px);
  box-shadow: 0px 0px 4px rgb(83 124 196 / 30%);
  border-radius: 6px;
  background-color: $white;
  border: none;

  .notify-item {
    &.dropdown-item {
      font-family: $font-poppins-regular;
      font-size: 14px;

      &.active {
        background-color: $accent-blue-100;
        color: $white;
      }
    }
  }
}

// Generate serie
.generate-serie {
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: #f4f5f9;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 15px;

  .serie-title {
    font-size: 16px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    text-transform: uppercase;
  }

  .card-item-bg {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.series-list {
  .serie {
    margin-bottom: 20px;
    padding: 15px;
    background: #f4f5f9;
    border: 1px solid #dddddd;
    border-radius: 6px;

    .serie-header {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;

      .cover {
        width: 140px;
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background: white;
          border: 1px solid #ebecf0;
          border-radius: 6px;
        }
      }

      .serie-info {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .serie-data {
          display: flex;
          flex-direction: column;
          gap: 7px;

          > div {
            span {
              font-size: 15px;
              line-height: 17px;
              color: #374661;
              font-family: "Poppins-Regular";

              &.title {
                font-family: "Poppins-Medium";
                text-transform: uppercase;
                margin-right: 5px;
              }
            }
          }
        }
      }

      .serie-buttons {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;
        margin-top: 15px;
      }

      .serie-actions {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        margin-left: auto;
      }
    }

    .item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;

      .item-attributes-summary {
        width: calc(100% - 45px);
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        padding: 10px 15px;
        background: $grey-blue-400;
        border: 1px solid $table-border;
        border-radius: 6px;

        .item-row {
          display: flex;
          column-gap: 4px;
          flex-wrap: wrap;

          .item-title {
            font-family: $font-poppins-medium;
            font-size: 15px;
            line-height: 20px;
            color: $grey-blue-750;
          }

          .item-value {
            font-family: $font-poppins-regular;
            font-size: 15px;
            line-height: 20px;
            color: $grey-blue-750;
          }
        }

        > div {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          width: calc(100% - 38px);

          > div {
            row-gap: 5px;
            display: flex;
            flex-direction: column;
          }
        }
      }

      .item-action {
        display: flex;
        justify-content: flex-end;
        width: 45px;
      }

      &:first-of-type {
        margin-top: 20px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      @media (max-width: 1280px) {
        width: 100%;
      }
    }
  }
}

.border-items:first-child {
  border-top: none;
}

.border-items {
  margin-top: 20px;
}

.display-content {
  display: contents;
}

// Sites
.sites-list {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  .site {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    padding: 20px;
    width: 100%;
    background: $grey-blue-400;
    border: 1px solid $grey-blue-500;
    border-radius: 10px;

    .site-info-block {
      display: flex;
      justify-content: space-between;

      .site-data {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;

        .item-row {
          column-gap: 4px;
          display: flex;
          flex-wrap: wrap;

          .item-title {
            width: 100%;
            color: $grey-blue-750;
            font-family: $font-poppins-medium;
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;

            &:hover {
              color: $accent-blue-700;
            }
          }
          .item-value {
            width: 100%;
            color: $grey-blue-750;
            font-family: $font-poppins-regular;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    .site-quick-links {
      border-top: 1px solid $grey-blue-600;
      padding-top: 15px;

      span {
        width: 100%;
        color: $grey-blue-750;
        font-family: $font-poppins-medium;
        font-size: 15px;
        line-height: 20px;
      }

      .quick-links {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 5px;

        a {
          color: $grey-blue-750;
          font-family: $font-poppins-regular;
          font-size: 14px;
          line-height: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            box-sizing: border-box;
            font-style: inherit;
            background: $accent-blue-250;
            line-height: initial;
            align-content: center;
            padding: 0px 2px;
            border-radius: 9.6px;
            font-weight: normal;
            text-transform: none;
            margin: 3px;
            width: 30px;
            min-width: 30px;
            height: 16px;
            text-align: center;
            color: $grey-blue-750;
            font-size: 11px;
          }

          &:hover {
            margin: 0 -5px;
            padding: 0 5px;
            background: $grey-blue-600;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

// Dropdown
.dropdown-white.show {
  padding: 5px 0;
  transform: translate(0px, 5px);
  box-shadow: 0px 0px 4px rgb(83 124 196 / 30%);
  border-radius: 6px;
  background-color: $white;
  border: none;

  .notify-item {
    &.dropdown-item {
      font-family: $font-poppins-regular;
      font-size: 14px;

      &.active {
        background-color: $accent-blue-100;
        color: $white;
      }
    }
  }
}

.table-list-results {
  font-family: $font-poppins-medium;
  font-size: 16px;
  color: $grey-blue-750;
  text-align: center;

  &.no-classification-selected {
    width: calc(100% - 35px - 30%);

    @media (max-width: 927px) {
      width: 100%;
    }
  }
}

.sidebar-filter {
  display: flex;
  flex-wrap: wrap;
  &.gap {
    gap: 35px;
    position: relative;
    padding-top: 65px;

    .add-item-row {
      position: absolute;
      right: 0;
      top: 0px;
    }

    @media (max-width: 927px) {
      padding-top: 0;
      gap: 20px;

      .add-item-row {
        position: relative;
      }
    }
  }
  .sidebar-content {
    width: 30%;
  }
  .table-content {
    width: calc(100% - 35px - 30%);
  }
}

@media (max-width: 927px) {
  .sidebar-filter {
    .sidebar-content {
      width: 100%;
    }
    .table-content {
      width: 100%;
    }
  }
}

.sidebar-filter-list {
  background: #f4f5f9;
  border-radius: 10px;
  padding: 10px;

  .sidebar-item {
    border-bottom: 1px solid #dfe1e5;

    .sidebar-header {
      display: flex;
      align-items: center;
      gap: 15px;

      .sidebar-title {
        width: calc(100% - 46px);
        text-align: left;
        font-family: "Poppins-Regular";
        font-size: 16px;
        line-height: 24px;
        color: #1c2b4b;
        padding: 10px 5px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;

        .accordion-icon {
          transform: rotate(0deg);
          transition: 0.3s;
        }

        &[aria-expanded="true"] {
          .accordion-icon {
            transform: rotate(180deg);
          }
        }
      }

      .sidebar-blue-button {
        width: 31px;
        padding: 7px;
        background: $accent-blue-100;
        border: 1px solid $accent-blue-100;
        border-radius: 5px;
        outline: none;
        line-height: 15px;

        &:hover {
          background: $accent-blue-200;
          box-shadow: $btn-blue-bg-hover-shadow;
          border: 1px solid $accent-blue-200;
          transition: 0.3s;
        }
        &:active {
          background: $grey-blue-950;
          border: 1px solid $grey-blue-950;
          box-shadow: none;
          transition: 0.3s;
        }

        .icon {
          background: $white;
          width: 15px;
          height: 15px;
        }

        &.has-icon {
          display: inline-block;

          > i {
            margin: 0px 7px -4px 0;
          }
        }
      }
    }

    .accordion-collapse {
      .list-unstyled {
        margin-bottom: 0;

        li {
          display: flex;
          padding: 0 10px 0 10px;
          border-bottom: 1px solid #dfe1e5;

          > * {
            font-family: "Poppins-Light";
            font-size: 17px;
            padding: 6px 0;
            color: #8da6e1;
          }

          &.active-item > * {
            color: #1f54c5 !important;
          }

          .classification-name {
            width: calc(100% - 62px);
            cursor: pointer;

            @media (max-width: 1200px) {
              word-break: break-all;
            }
          }

          .classification-action {
            width: 62px;
          }

          .m-padding {
            text-align: right;
          }

          &:hover {
            > * {
              color: #1f54c5;
            }
          }

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}

// Upload doocument popup
.dropdown-file {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  .dynamic-rows {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .upload-file {
    display: flex;
    flex-direction: column;
    .bar {
      width: auto;
    }
  }
  .dropdown-file {
    .bar {
      width: auto;
    }
  }
}

// Storage capacity
.storage-bar {
  position: relative;
  margin-top: -85px;
  padding: 10px 15px 0;
  background: $grey-blue-900;
  z-index: 1;

  .title {
    font-size: 15px;
    font-family: $font-poppins-regular;
  }
  .progress {
    background: rgba(255, 255, 255, 0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    display: flex;
    height: 7px;
    width: 100%;

    .progress-value {
      animation: load 3s normal forwards;
      box-shadow: 0 10px 40px -10px #fff;
      border-radius: 100px;
      background: #fff;
      height: 20px;
      width: var(--percentage-complete, 0%);
    }
  }
  .percentage-text {
    font-size: 12px;
    font-family: $font-poppins-regular;
  }
}

// Document folders
.back-button {
  i {
    width: 15px;
    height: 15px;
    mask-size: 12px;
  }
}

.folders-title {
  font-family: $font-poppins-medium;
  font-size: 16px;
  text-transform: uppercase;
  color: $grey-blue-700;
  margin-bottom: 10px;

  span {
    color: $grey-blue-750;
  }
}

.folders-container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  .folder {
    display: flex;
    gap: 15px;
    padding: 15px;
    background: $grey-blue-400;
    border: 1px solid $grey-blue-500;
    border-radius: 10px;

    .folder-name {
      width: calc(100% - 76px);
      color: $grey-blue-750;
      font-family: $font-poppins-medium;
      font-size: 16px;
      line-height: 20px;
      padding-top: 2px;
      cursor: pointer;

      &:hover {
        color: $accent-blue-700;
      }
    }

    i {
      background-color: $grey-blue-750;
    }
  }
}

.documents-search {
  display: grid;
  grid-gap: 0 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}


.popup-message {
  margin-top: 15px;
  text-align: left;
  font-family: $font-poppins-regular;
  font-size: 11px;
  color: $orange;
}