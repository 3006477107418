.login-view {
  height: 100vh;
  overflow: hidden;
  width: 100%;

  .left-auth-page {
    height: 100vh;
    width: 50%;
    position: relative;
    background-image: linear-gradient(#1e1e2c8f, #00000015),
      url(../images/banners/login-background.jpg);
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .main-logo {
      padding: 60px 60px;
    }
    .main-title {
      height: fit-content;
      place-self: center;
      margin: auto;
      position: absolute;
      top: 47%;
      width: 100%;

      h1 {
        text-align: center;
        color: $white;
        font-size: 64px;
        text-transform: uppercase;
        vertical-align: middle;
        font-family: $font-poppins-semibold;
        letter-spacing: 0.5px;
      }
    }

    @media (max-width: 767px) {
      height: 45vh;
      width: 100%;

      .main-title {
        h1 {
          font-size: 44px;
          text-align: left;
          padding: 0 25px;
        }
      }
      .main-logo {
        padding: 25px;
      }
    }
  }

  .right-auth-page {
    width: 50%;
    overflow-y: auto;
    height: 100%;
    position: relative;

    .form-logins {
      padding: 40px 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 55px;
      height: 100%;

      .login_bg {
        width: 70%;
        padding: 40px;
        background: $grey-blue-400;
        border: 1px solid $grey-blue-500;
        border-radius: 10px;

        .login_title {
          font-family: $font-poppins-medium;
          font-size: 24px;
          line-height: 36px;
          color: $black;
        }

        .login_note {
          font-family: $font-poppins-light;
          font-size: 16px;
          line-height: 24px;
          color: $black;
          margin-bottom: 25px;
        }

        .form-check-auth {
          display: flex;
          justify-content: space-between;
          gap: 25px;

          .forgot-password {
            a {
              font-family: $font-poppins-regular;
              font-size: 14px;
              line-height: 21px;
              color: $accent-blue-500;

              &:hover {
                color: $accent-blue-100;
                transition: 0.4s;
              }
            }
          }

          @media (max-width: 400px) {
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 20px;
            .form-group {
              margin-bottom: 0;
            }
          }
        }

        .user-data {
          margin-bottom: 30px;
          display: flex;
          gap: 15px;
          align-items: center;
          word-break: break-all;

          .initials {
            height: 55px;
            width: 55px;
            min-height: 55px;
            min-width: 55px;
            background: $grey-blue-500;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $grey-blue-800;
            text-transform: uppercase;

            span {
              font-size: 20px;
              color: $grey-blue-800;
              font-family: $font-poppins-medium;
            }
          }

          .user-info {
            display: flex;
            flex-direction: column;
            font-family: $font-poppins-regular;
            color: $grey-blue-800;
          }
        }

        @media (max-width: 992px) {
          width: 100%;
        }

        @media (max-width: 480px) {
          padding: 25px;
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 767px) {
    height: auto;
    overflow: unset;
  }
}
