.bl_page {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
}
@media (max-width: 1500px) {
  .bl_page {
    padding: 0px 76px;
  }
}
@media (max-width: 1200px) {
  .bl_page {
    padding: 0px 31px;
  }
}
@media (max-width: 768px) {
  .bl_page {
    padding: 0px 15px;
  }
}

.item-desc {
  .cover-most-read-books {
    width: 200px;
    height: 260px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #fff;
      border-radius: 6px;
    }
  }
  .most-read-books-detail {
    max-width: 200px;
    .type-m {
      color: #b7c3ce;
      font-family: "Galano Grotesque Regular", sans-serif;
      line-height: 1.2;
      padding-bottom: 10px;
      font-size: 16px;
      text-transform: uppercase;
    }
    .title-m {
      a {
        color: #007aff;
        font-size: 18px;
        font-family: "Galano Grotesque Medium", sans-serif;
        line-height: 1.2;
        text-transform: none;
      }
    }
    .author-m {
      color: #6c767e;
      font-family: "Galano Grotesque Regular", sans-serif;
      line-height: 1.2;
      font-size: 16px;
      word-break: break-word;
    }
  }
}

@media (max-width: 768px) {
  .item-desc {
    .cover-most-read-books {
      margin: auto;
    }
    .most-read-books-detail {
      margin: auto;
    }
  }
}
@media (max-width: 576px) {
  .item-desc {
    .cover-most-read-books {
      width: 150px;
      height: 200px;
    }
    .most-read-books-detail {
      max-width: 150px;
    }
  }
}

.navsection-logo,
.navsection-mobile,
.navsection-search {
  display: flex;
  align-items: center;
  height: 50px;
}
/* Styling Logo Section */
.navsection-logo {
  flex: 1 0 auto;
}

/* Styling Primary Section */
.frontpage {
  .header {
    min-height: 100vh;
  }
}

.header {
  .specific-search {
    margin: 0 -30px !important;
    padding: 0 10px;
    height: 0;
    opacity: 0;
    transition: opacity 0.22s ease;
    background: #fff;
    color: #000000ff;
  }
}

/*breadcrumb*/
.breadcrumb {
  background: unset;
  font-family: "Galano Grotesque Medium";
  a {
    color: #007aff;
  }
  span {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.b--breadcrumb {
  .breadcrumb-item {
    a {
      text-transform: initial !important;
      text-decoration: none;
      font-family: "Galano Grotesque Medium", sans-serif;
    }
  }
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */
#filter {
  #sidebar1 {
    max-width: 250px;
    background: white;
    color: black;
    transition: all 0.3s;
    .components {
      li {
        a {
          padding: 15px;
          font-size: 17px;
          display: block;
          font-family: "Galano Grotesque Regular", sans-serif;
          color: #6c767e;
          border-bottom: 1px solid #e4e7eb;
          text-transform: none;
          text-decoration: none;
          &.dropdown-toggle {
            position: relative;
            &:after {
              display: block;
              position: absolute;
              top: 40%;
              right: 20px;
              transform: translateY(-50%);
              border: solid #6c7696;
              border-width: 0 2px 2px 0;
              padding: 3px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
            }
          }
          &.autor-nav {
            border-top: 1px solid #e4e7eb;
          }
        }
        ul {
          a {
            padding: 10px 15px;
            border: none;
            margin: 0;
            display: flex;
            div {
              font-size: 15px;
            }
            &:hover {
              color: #b7c3ce;
              background: #cce4ff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #filter {
    display: none;
    &.show {
      display: block;
      padding: 0;
    }
    #sidebar1 {
      max-width: 100%;
      .components {
        li {
          ul {
            li {
              a {
                div {
                  &.m-padding {
                    text-align: right;
                    padding: 0 47px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#search-results {
  .results-data {
    display: flex;
    .nr-results {
      padding-left: 15px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: "Galano Grotesque Regular", sans-serif;
      color: #6c767e;
    }
    .based {
      padding-left: 22px;
      margin-left: 22px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: "Galano Grotesque Regular", sans-serif;
      color: #b7c3ce;
      position: relative;
      flex-wrap: wrap;
      &:after {
        border-right: 1px solid #dee2e6;
        content: "";
        top: 0;
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
      }
    }
  }
  #btnContainer {
    .list-view {
      position: relative;
      margin-right: 30px;
      &:focus {
        box-shadow: none;
        &:after {
          border-right: 1px solid #dee2e6;
          content: "";
          top: 0px;
          bottom: 0;
          display: block;
          right: -19px;
          position: absolute;
          height: 40px;
        }
      }
      &.active {
        img {
          filter: invert(65%) sepia(53%) saturate(6938%) hue-rotate(201deg)
            brightness(104%) contrast(106%);
        }
      }
    }
    .grid-view:focus {
      box-shadow: none;
      &.active {
        img {
          filter: invert(65%) sepia(53%) saturate(6938%) hue-rotate(201deg)
            brightness(104%) contrast(106%);
        }
      }
    }
  }
  .m-display {
    display: flex;
    margin: 0px;
  }
  .m-padding {
    padding-left: 47px;
    padding-right: 0px;
  }
  .cover-search-img {
    max-width: 130px;
    min-width: 130px;
    height: 170px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: #fff;
      border: 1px solid #6bb9fc;
      border-radius: 6px;
    }
  }
  .results {
    &:first-child {
      border-top: 1px solid #dee2e6;
    }
    &.grid-container {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
      .result1 {
        flex-direction: column;
        margin: 0;
        border: none;
        .info-material {
          padding: 0 30px 0 0;
          .type {
            padding: 10px 0 0;
          }
          .titull {
            line-height: 20px;
            a {
              font-size: 18px;
              line-height: 10px;
              text-decoration: none;
            }
          }
        }
      }
    }
    .result1 {
      border-top: 1px solid #dee2e6;
      padding: 20px 0;
      flex-wrap: nowrap;
      margin: 0;
      &:-moz-last-node {
        border-bottom: 1px solid #dee2e6;
      }
      .info-material {
        padding-left: 80px;
        align-self: center;
        width: 100%;
        .type {
          color: #b7c3ce;
          padding-bottom: 10px;
          font-size: 16px;
          font-family: "Galano Grotesque Regular", sans-serif;
          text-transform: uppercase;
        }
        .title {
          line-height: 20px;
          a {
            color: #007aff;
            font-size: 18px;
            font-family: "Galano Grotesque Medium", sans-serif;
            text-transform: none;
            text-decoration: none;
          }
        }
        .autor {
          color: #6c767e;
          font-size: 16px;
          font-family: "Galano Grotesque Regular", sans-serif;
          word-break: break-word;
        }
      }
      div {
        float: left;
      }
    }
  }
}

@media (max-width: 768px) {
  #search-results {
    .results-data {
      width: 100%;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #dee2e6;
      flex-direction: column;
      .based {
        padding-left: 0;
        margin-left: 0;
        .filter-tag {
          margin-bottom: 7px;
        }
        &:after {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #search-results {
    #btnContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
@media (min-width: 768px) {
  #search-results {
    #data-info {
      flex-wrap: nowrap;
    }
  }
}

@media (max-width: 576px) {
  #search-results {
    .results {
      .result1 {
        .info-material {
          padding-left: 30px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  #search-results {
    .results {
      &.grid-container {
        grid-template-columns: 33.33% 33.33% 33.33%;
        border: none;
      }
    }
  }
}
@media (max-width: 576px) {
  #search-results {
    .results {
      &.grid-container {
        grid-template-columns: 50% 50%;
      }
    }
  }
}

/* Mobile menu */
.navsection-mobile {
  .mobilemenu-wrapper {

    display: flex;
    justify-content: space-around;
    flex-direction: column;
    cursor: pointer;
    height: 26px;
    width: 30px;
    z-index: 500;
    &.active {
      position: fixed;
      right: 25px;
      &.loggedin {
        top: 60px;
      }
    }
    .bars {
      background: #fff;
      display: block;
      height: 3px;
      width: 100%;
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease;
      &:nth-of-type(3) {
        width: 60%;
      }
      &:nth-child(1) {
        -webkit-transform: translate(3px, 2px) rotate(45deg);
        transform: translate(3px, 2px) rotate(45deg);
        width: 20px;
        background: #002c3e;
      }
      &:nth-child(2) {
        -webkit-transform: translateX(-700%);
        transform: translateX(-700%);
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: translate(3px, -2px) rotate(-45deg);
        transform: translate(3px, -2px) rotate(-45deg);
        width: 20px;
        background: #002c3e;
      }
    }
  }
  .overlay {
    background: #0000008c;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    visibility: hidden;
    transition: visibility 0.35s, width 0.35s;
    overflow: auto;
    z-index: 99;
    ul {
      display: inline-block;
      margin: 40px 0 0;
      position: relative;
      li {
        display: block;
        position: relative;
        opacity: 0;
        font-size: 20px;
        a {
          display: block;
          position: relative;
          overflow: hidden;
          font-family: "Galano Grotesque Medium";
        }
      }
    }
    &.open {
      visibility: visible;
      height: 100%;
      li {
        -webkit-animation: fadeInRight 0.5s ease forwards;
        animation: fadeInRight 0.5s ease forwards;
        -webkit-animation-delay: 0.15s;
        animation-delay: 0.15s;
        &.main-li {
          -webkit-animation: none;
          animation: none;
        }
        &:nth-of-type(2) {
          -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
        }
        &:nth-of-type(3) {
          -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
        }
        &:nth-of-type(4) {
          -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
        }
        &:nth-of-type(4) {
          -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
        }
        &:nth-of-type(5) {
          -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
        }
      }
      .menu-div {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: 0 !important;
        right: 0;
        background: #002c3e;
        width: 60%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        ul {
          margin: 0;
          text-align: left;
          padding: 0;
          width: 100%;
          .main-li {
            background: #f0f7ff;
            opacity: 1;
            padding: 20px 50px;
            .login-button {
              padding: 12px 30px;
              border: 1px solid #002c3e;
              width: max-content;
              border-radius: 6px;
              color: #002c3e;
              font-size: 16px;
              margin: 12px 0;
            }
            ul {
              li {
                margin-bottom: 10px;
                > * {
                  color: #002c3e;
                  width: max-content;
                  display: flex;
                  font-family: "Galano Grotesque Medium";
                  align-items: center;
                  > svg {
                    margin-right: 4px;
                  }
                }
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
              &.menu-mob {
                padding: 40px 50px;
                li {
                  margin-bottom: 15px;
                  a {
                    color: #fff;
                    font-family: "Galano Grotesque Medium", sans-serif;
                    font-size: 17px;
                    max-width: max-content;
                    letter-spacing: 1px;
                  }
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .navsection-mobile {
    .overlay {
      &.open {
        .menu-div {
          width: 100%;
          ul {
            &.menu-mob {
              padding: 30px;
            }
            li {
              &.main-li {
                padding: 20px 30px;
              }
            }
          }
        }
      }
    }
  }
}

/* pdf reader */
.pdf_reader {
  height: 900px;
}
@media (max-width: 1400px) {
  .pdf_reader {
    height: 700px;
  }
}
@media (max-width: 767px) {
  .pdf_reader {
    height: 600px;
  }
}
@media (max-width: 576px) {
  .pdf_reader {
    height: 500px;
  }
}

/* pdf reader */

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(70px, 0px, 0px);
    transform: translate3d(70px, 0px, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }
}

@media (max-width: 767px) {
  .suggested_books_module {
    .suggested_book_body {
      width: calc(50% - 15px);
      margin-right: 15px;
      margin-bottom: 20px;
    }
  }
  #profile {
    .initials {
      background: #002c3e;
      border: 2px solid white;
      border-radius: 50%;
      width: 125px;
      height: 125px;
      color: white;
      transform: translateY(0%);
      margin: auto;
      margin-top: 25px;
    }
  }

  .member-details-profile {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 500px) {
  .suggested_books_module {
    .suggested_book_body {
      width: calc(104% - 9px);
      margin-right: 10px;
      margin-bottom: 21px;
      text-decoration: none;
    }
  }
  .book_details {
    text-align: center;
  }
}

//report bibliography
.pb-3 {
  padding-bottom: 1rem !important;
}
.py-3 {
  padding-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}
.my-2 {
  margin-bottom: 0.5rem !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

form {
  display: block;
  margin-top: 0em;
}

//stats
.stats-title {
  font-family: $font-poppins-medium;
  letter-spacing: 0.5px;
  color: $grey-blue-900;
}

.font-smaller {
  font-size: 20px;
}

.detailed-biblio-infohead {
  border-left: 4px solid $grey-blue-700;
  padding-left: 25px;

  .material-type {
    font-family: $font-poppins-bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: $accent-blue-100;
  }

  .material-title {
    font-family: $font-poppins-medium;
    font-size: 20px;
    line-height: 24px;
    color: $grey-blue-900;
    margin-bottom: 10px;
  }

  .material-author {
    font-family: $font-poppins-regular;
    font-size: 14px;
    line-height: 16px;
    color: $grey-blue-750;
  }

  @media (max-width: 480px) {
    padding-left: 10px;
  }
}

.image-holder {
  margin-top: 3rem;
  img {
    max-width: 160px;
    width: 100%;
    border-radius: 4px;
  }
}
