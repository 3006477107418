// Colors
$grey-blue-450: #fafbfc;
$grey-blue-400: #f4f5f9;
$grey-blue-500: #ebecf0;
$grey-blue-600: #dfe1e5;
$grey-blue-700: #bfc3cd;
$grey-blue-750: #747d8f;
$grey-blue-800: #374661;
$grey-blue-900: #1c2b4b;
$grey-blue-950: #24365e;
$accent-blue-25: #ebf1ff;
$accent-blue-700: #7e9eeb;
$accent-blue-500: #8da6e1;
$accent-blue-250: #bdc9e6;
$accent-blue-100: #1f54c5;
$accent-blue-200: #214b9e;
$purple-color: #9747ff;
$grey-color: #d9d9d9;
$red-color: #ee0000;
$yellow-color: #faad14;
$green-color: #52c41a;
$blue-status-color: #1890ff;
$validate-msg-color: #dc3545;
$sidebar-initials-bg: #ffecec;
$sidebar-initials-color: #d19595;
$submenu-background: #24365d;
$placeholder-color: #808080;
$checkbox-label-color: #aaaaaa;
$scrollbar-bg-color: #c1c1c1;
$neutral-200: #575757;
$table-border: #dddddd;

// Toastr
$toastr-success-bg: #f6ffed;
$toastr-success-border: #b7eb8f;
$toastr-success-icon: "../images/icons/toastr/success-icon.svg";

$toastr-info-bg: #e6f7ff;
$toastr-info-border: #91d5ff;
$toastr-info-icon: "../images/icons/toastr/info-icon.svg";

$toastr-warning-bg: #fffbe6;
$toastr-warning-border: #ffe58f;
$toastr-warning-icon: "../images/icons/toastr/warning-icon.svg";

$toastr-error-bg: #fff1f0;
$toastr-error-border: #ffccc7;
$toastr-error-icon: "../images/icons/toastr/error-icon.svg";

// Button
$btn-gray-color: #374661;
$btn-gray-bg: #ebecf0;
$btn-gray-bg-hover: #dfe1e5;
$btn-gray-bg-hover-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$btn-blue-bg-hover-shadow: 0px 4px 4px rgba(83, 124, 196, 0.3);
$btn-gray-bg-focus: #bfc3cd;
$btn-blue-color: #214b9e;
$btn-blue-bg-hover: #1f54c5;

// react select
$react-select-bg-hover-shadow: 0px 0px 4px rgba(83, 124, 196, 0.3);

// Fonts
$font-poppins-extrabold: "Poppins-ExtraBold";
$font-poppins-bold: "Poppins-Bold";
$font-poppins-light: "Poppins-Light";
$font-poppins-black: "Poppins-Black";
$font-poppins-regular: "Poppins-Regular";
$font-poppins-thin: "Poppins-Thin";
$font-poppins-extralight: "Poppins-ExtraLight";
$font-poppins-semibold: "Poppins-SemiBold";
$font-poppins-medium: "Poppins-Medium";

// tags
$green-tag-bg: #f6ffed;
$green-tag-border-color: #b7eb8f;
$green-tag-color: #52c41a;

$red-tag-bg: #fff1f0;
$red-tag-border-color: #ffa39e;
$red-tag-color: #f5222d;
