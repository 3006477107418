// labels
label {
  font-size: 16px;
  color: $black;
  line-height: 18px;
  font-family: $font-poppins-medium;
  margin-bottom: 7px;

  .biblioitem-tag {
    color: $grey-blue-750;
    font-family: $font-poppins-regular;
    margin-left: 7px;
  }
}

// global inputs
.form-block {
  position: relative;
  margin-bottom: 35px;

  .form-input {
    display: block;
    width: 100%;
    padding: 7px 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $grey-blue-900;
    background-color: $white;
    background-clip: padding-box;
    font-family: $font-poppins-regular;
    border: 1px solid $accent-blue-500;
    appearance: none;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;

    &:hover {
      border: 1px solid $accent-blue-100;
      box-shadow: 0px 0px 0px 1px $accent-blue-100;
      transition: 0.3s;
    }

    &:focus {
      border: 1px solid $accent-blue-100;
      box-shadow: none;
      transition: 0.3s;
    }

    &::placeholder {
      color: $placeholder-color;
      font-family: $font-poppins-regular;
    }

    &.input-error {
      &:hover {
        border: 1px solid $validate-msg-color;
        box-shadow: 0px 0px 0px 1px $validate-msg-color;
        transition: 0.3s;
      }
    }

    &.tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .tag {
        display: flex;
        align-items: center;
        background: $accent-blue-25;
        border: 1px solid $grey-blue-700;
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        color: $grey-blue-750;
        font-family: "Poppins-Regular";
        padding: 3px 7px;
        outline: none;

        button {
          background-color: $red-color;
          mask-size: 10px;
          width: 14px;
          height: 14px;
          margin-left: 6px;
        }
      }

      .empty-span {
        display: flex;
        align-items: center;
        color: $placeholder-color;
        font-family: $font-poppins-regular;
        min-height: 28px;
      }
    }
  }

  .form-react-select {
    cursor: pointer;

    .select_input__control {
      position: relative;
      min-height: 40px;
      width: 100%;
      outline: none;
      font-family: $font-poppins-regular;
      font-size: 1rem;
      letter-spacing: 0.4px;
      border: 1px solid $accent-blue-500;
      border-radius: 4px;
      box-shadow: none;
      text-align: left;

      &:hover {
        border: 1px solid $accent-blue-100;
        box-shadow: 0px 0px 0px 1px $accent-blue-100;
        transition: 0.3s;
      }
      &:focus {
        border: 1px solid $accent-blue-100;
        box-shadow: none;
        transition: 0.3s;
      }

      .select_input__value-container {
        padding: 0 13px;
      }

      .select_input__placeholder {
        color: $placeholder-color;
        font-weight: 400;
        font-size: 1rem;
        background-color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.select_input__control--menu-is-open {
        border-color: $accent-blue-100;
      }

      .select_input__value-container {
        .select_input__single-value {
          color: $grey-blue-900;
        }
      }
    }
    .select_input__menu {
      padding: 0px;
      transform: translate(0px, 5px);
      box-shadow: $react-select-bg-hover-shadow;
      border-radius: 6px;
      background-color: $white;
      border: none;
      margin-top: 3px;

      > div {
        color: $grey-blue-750;
        font-family: $font-poppins-regular;
        font-size: 15px;
        text-align: left;

        .select_input__option--is-selected {
          background-color: $accent-blue-200;
          color: $white;
        }

        .select_input__option--is-focused {
          background-color: $accent-blue-100;
          color: $white;
        }
      }
      > div::-webkit-scrollbar {
        width: 5px;
        height: 2px;
      }
      > div::-webkit-scrollbar-track {
        border-radius: 6px;
      }
      > div::-webkit-scrollbar-thumb {
        background: $accent-blue-100;
        border-radius: 6px;
      }
      > div::-webkit-scrollbar-thumb:hover {
        background: $accent-blue-100;
      }
    }
    &.input-error {
      .select_input__control {
        border-color: $validate-msg-color !important;
        &:hover {
          box-shadow: 0px 0px 0px 1px $validate-msg-color;
        }
      }
    }
  }

  .form-input-checkbox {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    + {
      label {
        position: relative;
        font-family: $font-poppins-regular;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: $black;
        cursor: pointer;

        &:before {
          content: "";
          background: $white;
          margin-right: 5px;
          border-radius: 4px;
          appearance: none;
          border: 1px solid $checkbox-label-color;
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
        }

        &:hover {
          &:before {
            border: 1px solid $accent-blue-100;
            transition: 0.3s;
          }
        }
      }
    }

    &:checked + {
      label {
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: auto;
          bottom: auto;
          left: 9px;
          right: auto;
          margin-top: -4px;
          width: 6px;
          height: 14px;
          border: solid $accent-blue-200;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
        &:before {
          border-color: $accent-blue-100;
          transition: 0.3s;
        }
      }
    }
  }

  .date_input {
    display: block;
    width: 100%;
    padding: 7px 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1c2b4b;
    background-color: #fff;
    background-clip: padding-box;
    font-family: "Poppins-Regular";
    border: 1px solid #8da6e1;
    appearance: none;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    cursor: pointer;

    &:hover {
      border: 1px solid $accent-blue-100;
      box-shadow: 0px 0px 0px 1px $accent-blue-100;
      transition: 0.3s;
    }

    &:focus {
      border: 1px solid $accent-blue-100;
      box-shadow: none;
      transition: 0.3s;
    }

    &::placeholder {
      color: $placeholder-color;
      font-family: $font-poppins-regular;
    }

    &.input-error {
      &:hover {
        border: 1px solid $validate-msg-color;
        box-shadow: 0px 0px 0px 1px $validate-msg-color;
        transition: 0.3s;
      }
    }
  }

  .react-datepicker__input-container {
    &:after {
      content: url(../images/icons/date-icon.svg);
      position: absolute;
      right: 10px;
      top: 5px;
      cursor: pointer;
    }
  }

  /* Datepicker */
  .react-datepicker-popper {
    .react-datepicker {
      padding: 0px;
      transform: translate(0px, 5px);
      box-shadow: $react-select-bg-hover-shadow;
      border-radius: 6px;
      background-color: $white;
      border: none;

      .react-datepicker__header {
        background: none;
        border-bottom: 1px solid $accent-blue-500;
      }
      .react-datepicker__day-name {
        text-transform: uppercase;
        font-size: 13px;
        font-family: $font-poppins-semibold;
        padding: 0 5px 5px;
        color: $grey-blue-900;
      }
      .react-datepicker__day {
        font-family: $font-poppins-regular;
        font-size: 14px;
        border-radius: 100%;
        border: none;
        background: none;
        display: inline-block;
        text-align: center;
        width: 25px;
        height: 25px;
        line-height: 25px;
        &.react-datepicker__day--selected {
          background: $accent-blue-200;
          color: $white;
          font-weight: normal;
          &:hover {
            border-radius: 100%;
            background-color: $accent-blue-200;
          }
        }

        &:hover {
          background: $accent-blue-500;
          color: $white;
        }
      }
      .react-datepicker__day--outside-month {
        color: $table-border;
      }
      .react-datepicker__day--keyboard-selected {
        color: $white;
        background: $accent-blue-100;
        font-weight: normal;
      }
      .react-datepicker__month-text {
        &:hover {
          border-radius: 100%;
          background-color: $white;
        }
      }
      .react-datepicker__quarter-text {
        &:hover {
          border-radius: 100%;
          background-color: $white;
        }
      }
      .react-datepicker__year-text {
        &:hover {
          border-radius: 100%;
          background-color: $white;
        }
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    &.react-datepicker__navigation--previous {
      display: none;
    }
    &.react-datepicker__navigation--next {
      display: none;
    }
  }

  .react-datepicker__current-month {
    &.react-datepicker__current-month--hasYearDropdown {
      &.react-datepicker__current-month--hasMonthDropdown {
        display: none;
      }
    }
  }

  .react-datepicker__month-dropdown-container {
    &.react-datepicker__month-dropdown-container--select {
      width: calc(50% - 15px);
      margin-right: 10px;
      .react-datepicker__month-select {
        width: 100%;
        background: $white;
        height: 35px;
        padding: 0;
        color: $grey-blue-900 !important;
        font-family: $font-poppins-regular;
        font-size: 14px;
        border: 1px solid $accent-blue-500;
        border-radius: 6px;
        outline: none;

        &:hover {
          border: 1px solid $accent-blue-100;
          box-shadow: 0px 0px 0px 1px $accent-blue-100;
          transition: 0.3s;
        }

        &:focus {
          border: 1px solid $accent-blue-100;
          box-shadow: none;
          transition: 0.3s;
        }

        &::placeholder {
          color: $placeholder-color;
          font-family: $font-poppins-regular;
        }
      }
    }
  }
  .react-datepicker__year-dropdown-container {
    &.react-datepicker__year-dropdown-container--select {
      width: calc(50% - 15px);
      .react-datepicker__year-select {
        width: 100%;
        background: $white;
        height: 35px;
        padding: 0;
        color: $grey-blue-900 !important;
        font-family: $font-poppins-regular;
        font-size: 14px;
        border: 1px solid $accent-blue-500;
        border-radius: 6px;
        outline: none;

        &:hover {
          border: 1px solid $accent-blue-100;
          box-shadow: 0px 0px 0px 1px $accent-blue-100;
          transition: 0.3s;
        }

        &:focus {
          border: 1px solid $accent-blue-100;
          box-shadow: none;
          transition: 0.3s;
        }

        &::placeholder {
          color: $placeholder-color;
          font-family: $font-poppins-regular;
        }
      }
    }
  }

  .upload-file-box {
    .form-input {
      position: relative;
      cursor: pointer;
      input {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
        z-index: 9;
      }

      .upload-input-placeholder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: break-word;

        span {
          color: $placeholder-color;
          font-family: $font-poppins-regular;
          line-height: 24px;
        }

        .upload-icon {
          background-color: $checkbox-label-color;
        }

        .close-red-icon {
          background-color: $validate-msg-color;
          opacity: 0.7;
          width: 16px;
          height: 16px;
          mask-size: 16px;

          &:hover {
            opacity: 1;
          }
        }
      }

      &:hover {
        .upload-input-placeholder {
          .upload-icon {
            background-color: $accent-blue-100;
          }
        }
      }

      &.input-error {
        .upload-input-placeholder {
          .upload-icon {
            background-color: $validate-msg-color;
          }
        }
      }
    }

    .uploaded-image {
      border: 1px solid $accent-blue-500;
      border-radius: 4px;
      margin-top: 7px;
      padding: 7px;
      width: 100%;

      .image-div {
        height: 120px;
        width: 120px;
        margin: auto;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .clear-title {
    position: relative;
    .close-red-icon {
      position: absolute;
      top: 14px;
      right: 10px;
      width: 12px;
      height: 12px;
      background: #dc3545;
      mask-size: 12px;
      cursor: pointer;
    }
  }
}

// authentication inputs
.form-group {
  position: relative;
  margin-bottom: 35px;

  .form-input {
    display: block;
    width: 100%;
    padding: 7px 15px 7px 45px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $grey-blue-900;
    background-color: $white;
    background-clip: padding-box;
    font-family: $font-poppins-regular;
    border: 1px solid $accent-blue-500;
    appearance: none;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;

    &:hover {
      border: 1px solid $accent-blue-100;
      box-shadow: 0px 0px 0px 1px $accent-blue-100;
      transition: 0.3s;
    }

    &:focus {
      border: 1px solid $accent-blue-100;
      box-shadow: none;
      transition: 0.3s;
    }

    &::placeholder {
      color: $placeholder-color;
      font-family: $font-poppins-regular;
    }

    &.input-error {
      &:hover {
        border: 1px solid $validate-msg-color;
        box-shadow: 0px 0px 0px 1px $validate-msg-color;
        transition: 0.3s;
      }
    }
  }

  .icon {
    position: absolute;
    top: 9px;
    left: 15px;
  }

  .show-hide {
    position: absolute;
    right: 15px;
    top: 9px;
    cursor: pointer;

    .icon {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  .form-input-checkbox {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    + {
      label {
        position: relative;
        font-family: $font-poppins-regular;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: $black;
        cursor: pointer;

        &:before {
          content: "";
          background: $white;
          margin-right: 5px;
          border-radius: 4px;
          appearance: none;
          border: 1px solid $checkbox-label-color;
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
        }

        &:hover {
          &:before {
            border: 1px solid $accent-blue-100;
            transition: 0.3s;
          }
        }
      }
    }

    &:checked + {
      label {
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: auto;
          bottom: auto;
          left: 9px;
          right: auto;
          margin-top: -4px;
          width: 6px;
          height: 14px;
          border: solid $accent-blue-200;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
        &:before {
          border-color: $accent-blue-100;
          transition: 0.3s;
        }
      }
    }
  }
}

// validation message
.invalid-message {
  width: 100%;
  position: absolute;
  margin-top: 5px;
  font-size: 14px;
  color: $validate-msg-color;
  line-height: 16px;
  text-align: left;
}

@media (max-width: 397px) {
  .invalid-message {
    width: 100%;
    position: relative;
    margin-top: 5px;
    font-size: 14px;
    color: $validate-msg-color;
    line-height: 16px;
    text-align: left;
  }
}

// validations inputs border
.input-error {
  border-color: $validate-msg-color !important;

  .tox {
    &.tox-tinymce {
      border: 1px solid $validate-msg-color;
      border-radius: 4px;
      &:hover {
        box-shadow: 0px 0px 0px 1px $validate-msg-color;
      }
    }
  }
}
