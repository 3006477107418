.table-list {
  table {
    thead {
      tr {
        th {
          background: $grey-blue-900;
          padding: 12px 10px;
          color: $white;
          font-family: $font-poppins-medium;
          font-weight: normal;
          font-size: 14px;
          text-transform: uppercase;
          &:first-of-type {
            padding-inline-start: 20px;
            border-top-left-radius: 4px;
          }
          &:last-of-type {
            padding-inline-end: 20px;
            border-top-right-radius: 4px;
          }
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
    tbody {
      tr {
        &.selected {
          background: $grey-blue-400;

          .small-text {
            color: $grey-blue-700;
            font-family: $font-poppins-regular;
            font-size: 12px;
          }
        }

        &:hover {
          background: $white;
        }

        td {
          height: 100px;
          border: none;
          border-bottom: 1px solid $table-border;
          padding: 20px 10px;
          color: $grey-blue-750;
          font-size: 15px;
          font-family: $font-poppins-regular;
          font-weight: normal;
          word-break: break-word;

          &:first-of-type {
            padding-inline-start: 20px;
          }
          &:last-of-type {
            padding-inline-end: 20px;
          }
          .active {
            font-family: $font-poppins-regular;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: $grey-blue-750;
            .dot {
              display: inline-flex;
              padding: 0px;
              width: 6px;
              height: 6px;
              background: $green-color;
              border-radius: 100px;
              margin-bottom: 1px;
            }
          }
          .inactive {
            font-family: $font-poppins-regular;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: $grey-blue-750;
            .dot {
              display: inline-flex;
              padding: 0px;
              width: 6px;
              height: 6px;
              background: $red-color;
              border-radius: 100px;
              margin-bottom: 1px;
            }
          }

          .action-icons {
            display: flex;
            flex-wrap: wrap;

            > * {
              width: auto;
            }
          }

          .description {
            p {
              margin-bottom: 0;
            }
          }
        }

        @media (max-width: 768px) {
          display: block;
          border-radius: 0;
          margin: 0 -25px;

          td {
            height: auto;
            display: flex;
            padding: 7px 0 !important;
            position: relative;
            text-align: left !important;
            border-bottom: none;
            word-wrap: break-word;
            width: calc(100% - 50px);
            margin: auto;
            min-height: 35px;
            font-size: 14px;
            line-height: 20px;

            &:has(.table-info-data) {
              padding: 14px 0 7px !important;

              .table-info-data {
                flex-direction: row;

                .cover {
                  min-width: 90px;
                  max-width: 90px;
                  min-height: 130px;
                  max-height: 130px;
                }

                .info-block {
                  .title {
                    font-size: 16px;
                    line-height: 18px;
                    text-align: left;
                  }
                  .subtitle {
                    text-align: left;
                  }
                }
              }

              &::before {
                content: none;
              }
            }

            &:before {
              content: attr(data-label);
              text-align: left;
              text-transform: uppercase;
              font-size: 14px;
              font-family: $font-poppins-medium;
              color: $grey-blue-900;
              padding-right: 30px;
              min-width: calc(50vw - 50px);
              max-width: calc(50vw - 50px);
            }

            &:last-child {
              border-bottom: 1px solid $table-border;
            }
          }

          &:first-of-type {
            border-top: 1px solid $table-border;
          }
          &:last-of-type {
            border-bottom: 1px solid $table-border;

            td {
              &:first-of-type,
              &:last-of-type {
                border: none;
              }
            }
          }
        }

        @media (max-width: 480px) {
          td {
            // flex-direction: column;

            // &:before {
            //   min-width: 100%;
            //   max-width: 100%;
            // }

            .action-icons {
              justify-content: flex-start !important;
            }
          }
        }
      }
    }
  }

  .table-list-results {
    width: 100%;
    text-align: center;
    padding: 15px;
    font-family: $font-poppins-medium;
    font-size: 18px;
    color: $grey-blue-900;
  }
}

.table-list-bg {
  table {
    thead {
      tr {
        th {
          background: $grey-blue-900;
          padding: 12px 10px;
          color: $white;
          font-family: $font-poppins-medium;
          font-weight: normal;
          font-size: 14px;
          text-transform: uppercase;
          &:first-of-type {
            padding-inline-start: 20px;
            border-top-left-radius: 4px;
          }
          &:last-of-type {
            padding-inline-end: 20px;
            border-top-right-radius: 4px;
          }
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
    tbody {
      tr {
        background: $grey-blue-400;
        &.selected {
          background: $grey-blue-900;
        }

        &:hover {
          background: $white;
        }

        td {
          height: 100px;
          border: none;
          border-bottom: 1px solid $table-border;
          padding: 20px 10px;
          color: $grey-blue-750;
          font-size: 15px;
          font-family: $font-poppins-regular;
          font-weight: normal;
          word-break: break-word;
          cursor: pointer;

          .table-info-data {
            display: flex;
            gap: 15px;
            align-items: center;

            .cover {
              min-width: 110px;
              max-width: 110px;
              min-height: 160px;
              max-height: 160px;
              height: 160px;
              border-radius: 4px;
              border: 1px solid $table-border;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .info-block {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .title {
                font-size: 20px;
                font-family: $font-poppins-semibold;
                color: $grey-blue-900;
                line-height: 24px;
              }
              .subtitle {
                font-size: 14px;
                font-family: $font-poppins-regular;
                color: $grey-blue-750$grey-blue-750;
              }
            }

            @media (max-width: 1440px) {
              flex-direction: column;

              .info-block {
                .title {
                  font-size: 18px;
                }
              }
            }
          }

          .acquisition-icon {
            width: 12px;
            height: 12px;
            background-color: $red-color;
            margin-top: 6px;
          }
          .approve-acquisition {
            background-color: $green;
          }
          .delivered {
            width: 51px;
            height: 22px;
            font-family: $font-poppins-regular;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: $grey-blue-750;
            .dot {
              display: inline-flex;
              padding: 0px;
              width: 6px;
              height: 6px;
              background: $green-color;
              border-radius: 100px;
              margin-bottom: 1px;
            }
          }
          .tobedelivered {
            width: 51px;
            height: 22px;
            font-family: $font-poppins-regular;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: $grey-blue-750;
            .dot {
              display: inline-flex;
              padding: 0px;
              width: 6px;
              height: 6px;
              background: $yellow-color;
              border-radius: 100px;
              margin-bottom: 1px;
            }
          }
          .delivered-late {
            width: 51px;
            height: 22px;
            font-family: $font-poppins-regular;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: $grey-blue-750;
            .dot {
              display: inline-flex;
              padding: 0px;
              width: 6px;
              height: 6px;
              background: $blue-status-color;
              border-radius: 100px;
              margin-bottom: 1px;
            }
          }
          .undelivered {
            width: 51px;
            height: 22px;
            font-family: $font-poppins-regular;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: $grey-blue-750;
            .dot {
              display: inline-flex;
              padding: 0px;
              width: 6px;
              height: 6px;
              background: $red-color;
              border-radius: 100px;
              margin-bottom: 1px;
            }
          }

          .action-icons {
            display: flex;
            flex-wrap: wrap;

            > * {
              width: auto;
            }
          }

          &:first-of-type {
            padding-inline-start: 20px;
          }
          &:last-of-type {
            padding-inline-end: 20px;
          }
        }

        @media (max-width: 768px) {
          display: block;
          border-radius: 0;
          margin: 0 -25px;

          td {
            height: auto;
            display: flex;
            padding: 7px 0 !important;
            position: relative;
            text-align: left !important;
            border-bottom: none;
            word-wrap: break-word;
            width: calc(100% - 50px);
            margin: auto;
            min-height: 35px;
            font-size: 14px;
            line-height: 20px;

            &:has(.table-info-data) {
              padding: 14px 0 7px !important;

              .table-info-data {
                flex-direction: row;

                .cover {
                  min-width: 90px;
                  max-width: 90px;
                  min-height: 130px;
                  max-height: 130px;
                }

                .info-block {
                  .title {
                    font-size: 16px;
                    line-height: 18px;
                    text-align: left;
                  }
                  .subtitle {
                    text-align: left;
                  }
                }
              }

              &::before {
                content: none;
              }
            }

            &:before {
              content: attr(data-label);
              text-align: left;
              text-transform: uppercase;
              font-size: 14px;
              font-family: $font-poppins-medium;
              color: $grey-blue-900;
              padding-right: 30px;
              min-width: calc(50vw - 50px);
              max-width: calc(50vw - 50px);
            }

            &:last-child {
              border-bottom: 1px solid $table-border;
            }
          }

          &:first-of-type {
            border-top: 1px solid $table-border;
          }
          &:last-of-type {
            border-bottom: 1px solid $table-border;

            td {
              &:first-of-type,
              &:last-of-type {
                border: none;
              }
            }
          }
        }

        // @media (max-width: 480px) {
        //   td {
        //     flex-direction: column;

        //     &:before {
        //       min-width: 100%;
        //       max-width: 100%;
        //     }
        //   }
        // }
      }
    }
  }

  .table-list-results {
    width: 100%;
    text-align: center;
    padding: 15px;
    font-family: $font-poppins-medium;
    font-size: 18px;
    color: $grey-blue-900;
  }
}

.table-list-no-bg {
  table {
    thead {
      tr {
        border-bottom: 1px solid $table-border;

        th {
          padding: 12px 10px;
          color: $grey-blue-750;
          font-family: $font-poppins-medium;
          font-weight: normal;
          font-size: 14px;
          text-transform: uppercase;
          &:first-of-type {
            padding-inline-start: 10px;
          }
          &:last-of-type {
            padding-inline-end: 10px;
          }
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
    tbody {
      tr {
        td {
          border: none;
          border-bottom: 1px solid $table-border;
          padding: 10px;
          color: $grey-blue-750;
          font-size: 15px;
          font-family: $font-poppins-regular;
          font-weight: normal;
          word-break: break-word;
          line-height: 20px;

          &:first-of-type {
            padding-inline-start: 10px;
          }
          &:last-of-type {
            padding-inline-end: 10px;
          }
          .action-icons {
            display: flex;
            flex-wrap: wrap;

            > * {
              width: auto;
            }
          }
        }

        @media (max-width: 768px) {
          display: block;
          border-radius: 0;
          margin: 0 -25px;

          td {
            height: auto;
            display: flex;
            padding: 7px 0 !important;
            position: relative;
            text-align: left !important;
            border-bottom: none;
            word-wrap: break-word;
            width: calc(100% - 50px);
            margin: auto;
            min-height: 35px;
            font-size: 14px;
            line-height: 20px;

            &:before {
              content: attr(data-label);
              text-align: left;
              text-transform: uppercase;
              font-size: 14px;
              font-family: $font-poppins-medium;
              color: $grey-blue-750;
              padding-right: 30px;
              min-width: calc(50vw - 50px);
              max-width: calc(50vw - 50px);
            }

            &:last-child {
              border-bottom: 1px solid $table-border;
            }
          }

          &:first-of-type {
            border-top: 1px solid $table-border;
          }
          &:last-of-type {
            border-bottom: 1px solid $table-border;

            td {
              &:first-of-type,
              &:last-of-type {
                border: none;
              }
            }
          }
        }

        @media (max-width: 480px) {
          td {
            // flex-direction: column;

            // &:before {
            //   min-width: 100%;
            //   max-width: 100%;
            // }

            .action-icons {
              justify-content: flex-start !important;
            }
          }
        }
      }
    }
  }

  .table-list-results {
    width: 100%;
    text-align: center;
    padding: 15px;
    font-family: $font-poppins-medium;
    font-size: 16px;
    color: $grey-blue-750;
  }
}

/*table nothing to show*/

.nothing--to--show {
  padding: 30px 30px;
  color: #002c3e;
  font-family: $font-poppins-regular;
  text-align: center;
}

@media screen and (max-width: 1500px) {
  .tooltip-text {
    max-width: 180px;
  }
}
@media screen and (max-width: 1200px) {
  .tooltip-text {
    max-width: 120px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1060px) {
  .info-material {
    &.d-inline-block {
      margin-right: auto;
    }
  }

  .d-inline-block {
    &.kopertina1 {
      &.image-vertical-aligment {
        margin-right: auto;
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  #report-stats {
    .tr-report-stats {
      height: auto;
    }
  }
}

.text-left {
  &.colored-th {
    color: #6c767e;
  }
}
.text-center {
  &.colored-th {
    color: #6c767e;
  }
}
.text-right {
  &.colored-th {
    color: #6c767e;
  }
}

.table-detail-tabs {
  .detailed-biblio-infohead {
    border-left: 2px solid #b7c3ce;
    .material-type {
      color: #007aff;
      text-transform: uppercase;
    }
    .material-title {
      font-family: "Galano Grotesque SemiBold", sans-serif;
      font-size: 20px;
      color: #002c3e;
      text-transform: uppercase;
    }
    .material-author {
      color: #6c767e;
    }
  }
}

.table-detail-tabs {
  .detailed-biblio-infohead [class*="col-"] {
    padding-left: 3rem;
  }
}
