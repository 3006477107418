#edit-biblioitem {
  .b-item {
    padding-left: 30px;
    border-radius: 8px;
    background-color: #002c3e;
    color: white;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: "Galano Grotesque Medium";
    text-transform: uppercase;
  }
  .piece {
    width: calc(100% - 60px);
  }
  .margin-dropdown {
    margin-left: 20px;
  }
  .dropdown-b {
    float: right;
    background-color: #007aff;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 40px;
    height: 40px;
    color: white;
    margin-right: 15px;
    outline: none;
    &.buton-open {
      background-color: transparent;
      padding: 0px !important;
      border: none;
      margin: 0;
    }
  }
  .fake-row {
    display: flex;
    margin: 0;
    flex-wrap: wrap;
  }
  .items-sets {
    background-color: #f0f7ff;
    margin-bottom: 30px;
    border: 1.5px solid #6bb9fc;
    border-radius: 8px;
    padding: 10px;
  }

  #create-item {
    form {
      > .bordered-row,
      > .container-createitem {
        position: relative;
        width: unset;
        padding-right: 35%;
        margin: 0;
      }
    }
  }
}

#edit-biblioitem-only {
  .b-item {
    padding-left: 30px;
    border-radius: 8px;
    background-color: #002c3e;
    color: white;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: "Galano Grotesque Medium";
    text-transform: uppercase;
  }
  .piece {
    width: calc(100% - 60px);
  }
  .margin-dropdown {
    margin-left: 20px;
  }
  .dropdown-b {
    float: right;
    background-color: #007aff;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 40px;
    height: 40px;
    color: white;
    margin-right: 15px;
    outline: none;
    &.buton-open {
      background-color: transparent;
      padding: 0px !important;
      border: none;
      margin: 0;
    }
  }
  .fake-row {
    display: flex;
    margin: 0;
    flex-wrap: wrap;
  }
  .items-sets {
    background-color: #f0f7ff;
    margin-bottom: 30px;
    border: 1.5px solid #6bb9fc;
    border-radius: 8px;
    padding: 10px;
  }

  #create-item {
    form {
      > .bordered-row,
      > .container-createitem {
        position: relative;
        width: unset;
        padding-right: 35%;
        margin: 0;
      }
    }
  }
}

.container-createitem {
  border-bottom: 1px solid $table-border;
  padding-bottom: 25px;

  .addFields {
    font-size: 20px;
    line-height: 1;
  }

  .removeFields {
    font-size: 20px;
    line-height: 1;
  }
}
.btn-styled-2 {
  &.addFields {
    &.float-left {
      font-size: 20px;
      line-height: 1;
    }
  }
}

#create-item {
  .add-remove-buttons {
    position: absolute;
    right: 25px;
    bottom: 25px;
    display: flex;
  }
  .addFields {
    bottom: 0px;
    float: right;
    width: 50px !important;
    color: white !important;
  }
  .removeFields {
    bottom: 0px;
    float: right;
    width: 50px !important;
    color: white !important;
  }
}

@media (max-width: 576px) {
  #edit-biblioitem {
    .b-item {
      padding: 6px 15px;
    }
  }
  #edit-biblioitem-only {
    .b-item {
      padding: 6px 15px;
    }
  }
}

.card-item-bg {
  background: $grey-blue-400;
  border: 1px solid $table-border;
  border-radius: 6px;
  margin: 0;
  padding: 20px 8px;

  .add-remove-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}

.card-item {
  border-bottom: 1px solid $table-border;
  border-radius: 0;
  margin: 0;
  padding: 20px 8px;

  .add-remove-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}
