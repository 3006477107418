/* Textarea */
.marc-confirm-records {
  .fake-textarea {
    background-color: white;
    vertical-align: middle;
    border: 2px solid #6bb9fc;
    border-radius: 6px;
    padding: 20px 10px 13px;
    min-height: 73px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    input {
      border: 1px solid #6bb9fc;
      border-radius: 4px;
      color: #6bb9fc;
      background-color: #f0f7ff;
      padding: 3px;
      margin-bottom: 7px;
      font-size: 14px;
      font-family: "Galano Grotesque Regular";
      max-width: fit-content;
      outline: none;
      margin-right: 7px;
      text-align: center;
    }
    input:nth-of-type(even) {
      margin-right: 0;
    }
  }
  .import--marc--file {
    #nrEntry-0 {
      border-bottom: 1px solid #bac1c6;
    }
    span {
      &.ui-selectmenu-button {
        &.ui-button + .error-tooltip {
          margin-top: -12px;
        }
      }
    }
    .card {
      border: none;
      border-radius: 0;
      .card-header {
        border-radius: 0;
        border-bottom: 1px solid #bac1c6 !important;
        height: auto;
        padding: 30px;

        > h5 {
          margin: 0;
        }
        .items-centered > div {
          color: #6c767e;
          font-size: 16px;
          font-family: $font-poppins-regular;
          font-weight: normal;
          word-break: break-word;
          text-align: center;
          margin: 0;
          align-self: center;
        }
        .btn-link:hover {
          text-decoration: none;
        }
      }
    }
    .series-div {
      span {
        &.error-message {
          left: 0;
          top: calc(100% - 25px);
        }
      }
      > div > div > div > div {
        position: relative;
      }
    }
  }
  .ui-selectmenu-button {
    &.ui-button {
      margin-bottom: 15px;
    }
  }
  .background-darkblue {
    height: 50px;
    color: #fff;
    background-color: #002c3e;
    border: 1px solid #002c3e;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
    margin: 0;
    > div {
      color: #fff;
      padding: 15px 0;
      font-size: 14px;
      text-transform: uppercase;
    }
    > div:first-of-type {
      padding-inline-start: 30px;
    }
    > div:last-of-type {
      padding-inline-end: 30px;
    }
  }
  .card-header {
    background-color: #fff;
    height: 110px;
    border: none !important;
  }
}

@media (max-width: 767px) {
  .marc-confirm-records {
    .import--marc--file {
      margin: 0 -50px;
      overflow: hidden;
      .card {
        .card-header {
          padding: 30px 10px;
          .special-text {
            &.edit {
              width: calc(100% - 100px) !important;
            }
          }
          .btn-link {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          .items-centered > div {
            font-size: 16px;
            display: block;
            padding: 14px 0 14px 40% !important;
            position: relative;
            text-align: right !important;
            border-bottom: none;
            word-wrap: break-word;
            width: calc(100% - 100px);
            margin: auto;
          }
          .items-centered > div:before {
            content: attr(data-label);
            float: left;
            text-transform: uppercase;
            font-size: 16px;
            color: #6c767e;
            padding-right: 30px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .background-darkblue {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .marc-confirm-records {
    .import--marc--file {
      margin: 0 -30px;
      overflow: hidden;
      .card {
        .card-header {
          .items-centered > div {
            width: calc(100% - 60px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 489px) and (max-width: 767px) {
  .marc-confirm-records {
    .card-header {
      height: 350px;
    }
  }
}
@media screen and (min-width: 360px) and (max-width: 488px) {
  .marc-confirm-records {
    .card-header {
      height: 400px;
    }
  }
}
@media screen and (max-width: 359px) {
  .marc-confirm-records {
    .card-header {
      height: 425px;
    }
  }
}
@media screen and (min-width: 768px) {
  .marc-confirm-records {
    .card-header > h5 {
      margin-top: revert;
    }
  }
}
.marc-confirm-records {
  .card-header {
    .edit {
      width: 20px !important;
    }
  }
}
@media screen and (min-width: 768px) {
  .marc-confirm-records {
    .card-header {
      .edit {
        height: 20px !important;
      }
    }
  }
}

.accordion-table {
  .header-accordion-table {
    display: flex;
    margin: 0;
    padding: 0;

    > div {
      background: #1c2b4b;
      padding: 12px 10px;
      color: #fff;
      font-family: "Poppins-Medium";
      font-weight: normal;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;

      &:first-of-type {
        padding-inline-start: 20px;
        border-top-left-radius: 4px;
      }

      &:last-of-type {
        padding-inline-end: 20px;
        border-top-right-radius: 4px;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .accordion-table-data {
    padding: 0;
    margin: 0;

    .accordion-table-result {
      background: #f4f5f9;
      border-bottom: 1px solid #dddddd;
      color: #747d8f;
      font-size: 15px;
      font-family: "Poppins-Regular";
      font-weight: normal;
      word-break: break-word;

      .accordion-table-item {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;

        > div {
          padding: 20px 12px;
          text-align: center;
          place-self: center;

          &:first-of-type {
            padding-left: 20px;
            text-align: left;
          }

          &:last-of-type {
            padding-right: 20px;
          }
        }
      }

      .accordion-collapse {
        border-top: 1px solid #dddddd;
        padding: 20px;
      }

      &:hover {
        background: #fff;
      }
    }
  }
}
